<ngx-spinner size="large" [type]="typeSelected"></ngx-spinner>

<app-header (notify)="goToHome($event)"></app-header>
<div class="main_div">
  <div class="stepper">
    <ul>
      <li [ngClass]="step >= 1 ? 'active' : ''">
        <span class="number" [ngClass]="step >= 2 ? 'step-number-bg-color' : ''"
          >1</span
        ><span class="text" [ngClass]="step >= 2 ? 'set-text-color' : ''"
          >Perimeter</span
        >
        <hr [ngClass]="step >= 2 ? 'border-btw-step' : ''" />
      </li>
      <li [ngClass]="step >= 2 ? 'active' : ''">
        <span class="number" [ngClass]="step >= 3 ? 'step-number-bg-color' : ''"
          >2</span
        ><span class="text" [ngClass]="step >= 3 ? 'set-text-color' : ''"
          >Clustering</span
        >
        <hr [ngClass]="step >= 3 ? 'border-btw-step' : ''" />
      </li>
      <li [ngClass]="step >= 3 ? 'active' : ''">
        <span class="number" [ngClass]="step >= 4 ? 'step-number-bg-color' : ''"
          >3</span
        ><span class="text" [ngClass]="step >= 4 ? 'set-text-color' : ''"
          >Degradation</span
        >
        <hr [ngClass]="step >= 4 ? 'border-btw-step' : ''" />
      </li>
      <li [ngClass]="step == 4 ? 'active' : ''">
        <span class="number">4</span
        ><span class="text">Sorting &amp; Inputs</span>
      </li>
    </ul>
  </div>

  <div class="card_div">
    <form class="form" [formGroup]="multiStepForm">
      <!-- Step one start (Perimiter)-->
      <div>
        <div class="message" *ngIf="id">
          Request Id {{ id }} saved {{ timeInterval }} ago
        </div>

        <ng-container *ngIf="step == 1">
          <div class="title">
            Pairing Perimeters
            <mat-icon
              onkeypress=""
              [matTooltipDisabled]="true"
              matTooltipClass="material-tooltip"
              #perimeterTooltip="matTooltip"
              matTooltip="{{ perimeterPageTooltip }}"
              (click)="displayTooltip(perimeterTooltip)"
              >info</mat-icon
            >
          </div>
          <div class="subtitle">
            Please fill in the details below. Fields marked with
            <span class="mandatory_star">*</span> are mandatory.
          </div>
          <div
            class="validation"
            *ngIf="
              (multiStepForm.controls['productLine'].invalid &&
                isProductLineSelected) ||
              (multiStepForm.controls['region'].invalid && isRegionSelected) ||
              (multiStepForm.controls['businessLine'].invalid &&
                isBusinessLineSelected)
            "
          >
            <img
              src="../../../../assets/images/warning.svg"
              alt="warning_icon"
            /><span
              >Please fill in the required details to proceed further.</span
            >
          </div>

          <div class="form_title">
            Region<span class="mandatory_star">*</span>
            <mat-icon
              onkeypress=""
              [matTooltipDisabled]="true"
              matTooltipClass="material-tooltip"
              #regionsTooltip="matTooltip"
              matTooltip="{{ regionTooltip }}"
              (click)="displayTooltip(regionsTooltip)"
              >info</mat-icon
            >
          </div>
          <div class="region_list">
            <div *ngFor="let region of regions; let i = index">
              <input
                type="radio"
                id="{{ region.regionCode }}"
                name="region"
                [value]="region.regionName"
                formControlName="region"
                (change)="onRegionId(region.regionId, region.regionCode)"
                required
              />
              <div>
                <label for="{{ region.regionCode }}">{{
                  region.regionCode
                }}</label>
              </div>
            </div>
          </div>

          <div class="country_product_line_div">
            <!-- hidden user Id Field -->
            <input
              type="hidden"
              class="form-control"
              formControlName="userId"
              id="id"
            />

            <div class="country_div dropdown_div">
              <div class="title">
                Country(s)
                <mat-icon
                  onkeypress=""
                  [matTooltipDisabled]="true"
                  matTooltipClass="material-tooltip"
                  #countrysTooltip="matTooltip"
                  matTooltip="{{ countryTooltip }}"
                  (click)="displayTooltip(countrysTooltip)"
                  >info</mat-icon
                >
              </div>
              <div class="dropdown">
                <ng-multiselect-dropdown
                  [placeholder]="'Select Country'"
                  [settings]="countryDropdownSettings"
                  [(ngModel)]="selectedCountries"
                  [ngModelOptions]="{ standalone: true }"
                  formArrayName="country"
                  [data]="countries"
                  (onSelect)="onCountrySelect($event)"
                  (onDeSelect)="onCountryDeselect($event)"
                  (onSelectAll)="onSelectAllCountrys($event)"
                  (onDeSelectAll)="onDeSelectAllCountys()"
                  [disabled]="isCountryDisabledOnRegion"
                >
                  {{ countries }}
                </ng-multiselect-dropdown>
              </div>
            </div>

            <div class="product_line_div dropdown_div">
              <div
                class="title"
                [ngClass]="{
                  'red-border-class':
                    multiStepForm.controls['productLine']?.errors &&
                    isProductLineSelected
                }"
              >
                Product Line <span class="mandatory_star">*</span>
                <mat-icon
                  onkeypress=""
                  [matTooltipDisabled]="true"
                  matTooltipClass="material-tooltip"
                  #plsTooltip="matTooltip"
                  matTooltip="{{ plTooltip }}"
                  (click)="displayTooltip(plsTooltip)"
                  >info</mat-icon
                >
              </div>
              <div
                class="dropdown"
                [ngClass]="{
                  'red-border-classs':
                    multiStepForm.controls['productLine']?.errors &&
                    isProductLineSelected
                }"
              >
                <ng-multiselect-dropdown
                  [ngClass]="{
                    'product_line_div.title':
                      multiStepForm.controls['productLine']?.errors &&
                      isProductLineSelected
                  }"
                  [placeholder]="'Select a Product Line'"
                  [settings]="productLineDropdownSettings"
                  [(ngModel)]="selectedProductLine"
                  [ngModelOptions]="{ standalone: true }"
                  [data]="productLines"
                  [disabled]="isProductLineDisabledOnRegion"
                  formArrayName="productLine"
                  (onSelect)="onProductLineSelect($event)"
                  (onDeSelect)="onProductLineDeselect($event)"
                  name="selectedProductLine"
                  required
                >
                  {{ productLines }}
                </ng-multiselect-dropdown>
              </div>
            </div>

            <div class="business_line_div dropdown_div">
              <div
                class="title"
                [ngClass]="{
                  'red-border-class':
                    multiStepForm.controls['businessLine']?.errors &&
                    isBusinessLineSelected
                }"
              >
                Business Line<span class="mandatory_star">*</span>
                <mat-icon
                  onkeypress=""
                  [matTooltipDisabled]="true"
                  matTooltipClass="material-tooltip"
                  #blsTooltip="matTooltip"
                  matTooltip="{{ blTooltip }}"
                  (click)="displayTooltip(blsTooltip)"
                  >info</mat-icon
                >
              </div>
              <div
                class="dropdown"
                [ngClass]="{
                  'red-border-classs':
                    multiStepForm.controls['businessLine']?.errors &&
                    isBusinessLineSelected
                }"
              >
                <ng-multiselect-dropdown
                  [ngClass]="{
                    'business_line_div.title':
                      multiStepForm.controls['businessLine']?.errors &&
                      isBusinessLineSelected
                  }"
                  [placeholder]="'Select a Business Line'"
                  [settings]="businessLineDropdownSettings"
                  [(ngModel)]="selectedBusinessLine"
                  [ngModelOptions]="{ standalone: true }"
                  [data]="businessLines"
                  [disabled]="isBusinessLineDisabledOnProductLine"
                  formArrayName="businessLine"
                  (onSelect)="onBusinessLineSelect($event)"
                  (onDeSelect)="onBusinessLineDeselect($event)"
                  name="selectedBusinessLine"
                  required
                >
                  {{ businessLines }}
                </ng-multiselect-dropdown>
              </div>
            </div>
          </div>

          <div class="brands_div">
            <div class="michelin_brands_div dropdown_div">
              <h6>
                Michelin brands

                <mat-icon
                  onkeypress=""
                  [matTooltipDisabled]="true"
                  matTooltipClass="material-tooltip"
                  #michelinBrandsTooltip="matTooltip"
                  matTooltip="{{ michelinBrandTooltip }}"
                  (click)="displayTooltip(michelinBrandsTooltip)"
                  >info</mat-icon
                >
              </h6>

              <div class="michelin_brands_inner_div">
                <div class="michelin_brands_row_main">
                  <div class="product_line_div dropdown_div">
                    <div class="title">Brand(s)</div>

                    <div class="dropdown" appendTo="body">
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Brand'"
                        [settings]="michelinBrandDropdownSettings"
                        [(ngModel)]="selectedMichelinBrand"
                        [ngModelOptions]="{ standalone: true }"
                        formArrayName="brandX"
                        [data]="michelinBrands"
                        (onSelect)="onMichelinBrandSelect($event)"
                        (onDeSelect)="onMichelinBrandDeselect($event)"
                        (onSelectAll)="onSelectAllMichelinBrands($event)"
                        (onDeSelectAll)="onDeSelectAllMichelinBrands()"
                        [disabled]="isMichelinBrandDisabled"
                      >
                        {{ michelinBrands }}
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div>
                    <div class="title">Sub Season(s)</div>

                    <div class="dropdown">
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Season'"
                        [settings]="seasonDropdownSettings"
                        [(ngModel)]="selectedSeason"
                        [ngModelOptions]="{ standalone: true }"
                        formArrayName="seasonX"
                        [data]="seasons"
                        (onSelect)="onSeasonSelect($event)"
                        (onDeSelect)="onSeasonDeselect($event)"
                        (onSelectAll)="onSelectAllSeasons($event)"
                        (onDeSelectAll)="onDeSelectAllSeasons()"
                        [disabled]="isSeasonDisabled"
                      >
                        {{ seasons }}
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                </div>

                <div class="michelin_brands_row_main">
                  <div>
                    <div class="title">Tyre Usage(s)</div>

                    <div class="dropdown">
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Tyre Usage'"
                        [settings]="michelinTyreUsageDropdownSettings"
                        [(ngModel)]="selectedTyreUsage"
                        [ngModelOptions]="{ standalone: true }"
                        formArrayName="usageX"
                        [data]="tyreUsages"
                        (onSelect)="onTyreUsageSelect($event)"
                        (onDeSelect)="onTyreUsageDeselect($event)"
                        (onSelectAll)="onSelectAllTyreUsages($event)"
                        (onDeSelectAll)="onDeSelectAllTyreUsages($event)"
                        [disabled]="isTireUsageDisabled"
                      >
                        {{ tyreUsages }}
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div>
                    <div class="title">Tireline(s)</div>

                    <div class="dropdown">
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Tireline'"
                        [settings]="michelinTyreLineDropdownSettings"
                        [(ngModel)]="selectedTyreLine"
                        [ngModelOptions]="{ standalone: true }"
                        formArrayName="tirelineX"
                        [data]="tyreLines"
                        (onSelect)="onTyreLineSelect($event)"
                        (onDeSelect)="onTyreLineDeselect($event)"
                        (onSelectAll)="onSelectAllTyreLines($event)"
                        (onDeSelectAll)="onDeSelectAllTyreLines()"
                        [disabled]="isTireLineDisabled"
                      >
                        {{ tyreLines }}
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="vl"></div>

            <div class="input_div set-cai-box">
              <label class="checkbox_div"
                >Need CAI List Upload?

                <input
                  type="checkbox"
                  [checked]="isCAIChecked"
                  [(ngModel)]="isCAIChecked"
                  (change)="onCheckedCAI($event)"
                  formControlName="isCAI"
                />

                <span class="checkmark"></span>
              </label>
              <mat-icon
                onkeypress=""
                [matTooltipDisabled]="true"
                matTooltipClass="material-tooltip"
                #caiFileTooltip="matTooltip"
                matTooltip="{{ caiTooltip }}"
                (click)="displayTooltip(caiFileTooltip)"
                >info</mat-icon
              >

              <ng-container *ngIf="isCAIChecked">
                <div class="subtitle">
                  <br />
                  To proceed next uploading a file is mandatory.
                </div>

                <div class="download_file_section">
                  <div>
                    <div class="file_name">CAI_List.xlsx</div>
                  </div>

                  <div onKeyPress="" (click)="downloadCAIFile()">
                    <img
                      class="download-icon"
                      src="../../assets/images/download.svg"
                      alt="download_icon"
                    />
                  </div>
                </div>

                <span *ngIf="errorFlag" class="cai-validation-msg"
                  >Uploading template is mandatory.</span
                >

                <div
                  class="upload_div upload_progress"
                  style="position: relative"
                >
                  <!-- checking file drop -->

                  <div class="center1">
                    <ngx-file-drop
                      dropZoneLabel="Drop files here"
                      [multiple]="false"
                      (onFileDrop)="droppedCAIFile($event)"
                      (onFileOver)="fileOver($event)"
                      (onFileLeave)="fileLeave($event)"
                    >
                      <ng-template
                        ngx-file-drop-content-tmp
                        let-openFileSelector="openFileSelector"
                      >
                        <span
                          class="upload-custom-text"
                          onKeyPress=""
                          (click)="openFileSelector()"
                          >Upload<br />
                          <span class="drag-font"
                            >Choose a file or Drag and drop
                          </span>
                        </span>
                      </ng-template>
                    </ngx-file-drop>
                  </div>

                  <!-- end -->
                </div>

                <div class="progress_div" *ngIf="successMessageCAI">
                  <div class="progress">
                    <div
                      class="progress-bar active"
                      aria-valuenow="40"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style="width: 100%"
                    ></div>
                  </div>

                  <div>100%</div>
                </div>

                <div class="file_name_div">
                  {{ getCAIFileName }}
                </div>
                <div class="file_name_div" *ngIf="getCAIFileSize">
                  <div class="file_size">
                    Size:
                    <span>{{ getCAIFileSize | formatFileSize : true }}</span>
                  </div>
                </div>
                <div class="validation" *ngIf="errorFlagCAI">
                  <img
                    src="../../assets/images/warning.svg"
                    alt="warning-icon"
                  /><span>{{ caiError }}</span>
                </div>
                <div class="success_msg" *ngIf="isCAIFileMissing">
                  <img
                    src="../../assets/images/sucess-icon.svg"
                    alt="success-icon"
                    width="18px"
                    height="18px"
                  />
                  {{ CAIListMessage }}
                </div>
                <div class="success_msg" *ngIf="successMessageCAI">
                  <img
                    src="../../assets/images/sucess-icon.svg"
                    alt="success-icon"
                    width="18px"
                    height="18px"
                  />
                  CAI List file uploaded successfully
                </div>
              </ng-container>
            </div>
          </div>

          <div class="brands_div">
            <div class="other_brands_div dropdown_div">
              <h6>
                Other Brands
                <mat-icon
                  onkeypress=""
                  [matTooltipDisabled]="true"
                  matTooltipClass="material-tooltip"
                  #otherBrandsTooltip="matTooltip"
                  matTooltip="{{ compitiorTooltip }}"
                  (click)="displayTooltip(otherBrandsTooltip)"
                  >info</mat-icon
                >
              </h6>

              <div class="michelin_brands_inner_div">
                <div class="michelin_brands_row_main">
                  <div>
                    <div class="title">Brand(s)</div>

                    <div class="dropdown">
                      <ng-multiselect-dropdown
                        title="{{ caiTooltip }}"
                        [placeholder]="'Select Brand'"
                        [settings]="otherBrandDropdownSettings"
                        [(ngModel)]="selectedOtherBrand"
                        [ngModelOptions]="{ standalone: true }"
                        formArrayName="brandY"
                        [data]="otherBrands"
                        (onSelect)="onOtherBrandSelect($event)"
                        (onDeSelect)="onOtherBrandDeselect($event)"
                        (onSelectAll)="onSelectAllOtherBrands($event)"
                        (onDeSelectAll)="onDeSelectAllOtherBrands()"
                      >
                        {{ otherBrands }}
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div>
                    <div class="title">Sub Season(s)</div>

                    <div class="dropdown">
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Season'"
                        [settings]="otherSeasonDropdownSettings"
                        [(ngModel)]="selectedOtherSeason"
                        [ngModelOptions]="{ standalone: true }"
                        formArrayName="seasonY"
                        [data]="otherSeasons"
                        (onSelect)="onOtherSeasonSelect($event)"
                        (onDeSelect)="onOtherSeasonDeselect($event)"
                        (onSelectAll)="onSelectAllOtherSeasons($event)"
                        (onDeSelectAll)="onDeSelectAllOtherSeasons()"
                        [disabled]="isOtherSeasonDisabled"
                      >
                        {{ otherSeasons }}
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                </div>

                <div class="michelin_brands_row_main">
                  <div>
                    <div class="title">Tyre Usage(s)</div>

                    <div class="dropdown">
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Tyre Usage'"
                        [settings]="otherTyreUsageDropdownSettings"
                        [(ngModel)]="selectedOtherTyreUsage"
                        [ngModelOptions]="{ standalone: true }"
                        formArrayName="usageY"
                        [data]="otherTyreUsages"
                        (onSelect)="onOtherTyreUsageSelect($event)"
                        (onDeSelect)="onOtherTyreUsageDeselect($event)"
                        (onSelectAll)="onSelectAllOtherTyreUsages($event)"
                        (onDeSelectAll)="onDeSelectAllOtherTyreUsages($event)"
                        [disabled]="isOtherTireUsageDisabled"
                      >
                        {{ otherTyreUsages }}
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div>
                    <div class="title">Tireline(s)</div>

                    <div class="dropdown">
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Tireline'"
                        [settings]="otherTirelineDropdownSettings"
                        [(ngModel)]="selectedOtherTireline"
                        [ngModelOptions]="{ standalone: true }"
                        formArrayName="tirelineY"
                        [data]="otherTirelines"
                        (onSelect)="onOtherTirelineSelect($event)"
                        (onDeSelect)="onOtherTirelineDeselect($event)"
                        (onSelectAll)="onSelectAllOtherTirelines($event)"
                        (onDeSelectAll)="onDeSelectAllOtherTirelines()"
                        [disabled]="isOtherTireLineDisabled"
                      >
                        {{ otherTirelines }}
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="vl"></div>

            <div class="input_div set-cai-box" style="display: contents">
              <label class="checkbox_div"
                >Exclude Michelin Brands from Competitors?
                <input
                  type="checkbox"
                  [checked]="isMichelinBrandsExcluded"
                  [(ngModel)]="isMichelinBrandsExcluded"
                  (change)="onExcludingMichelinBrands($event)"
                  formControlName="isMichelinBrandsExcluded"
                />
                <span class="checkmark"></span>
              </label>
              <mat-icon
                onkeypress=""
                [matTooltipDisabled]="true"
                matTooltipClass="material-tooltip"
                #excludesMichelinTooltip="matTooltip"
                matTooltip="{{ excludeMichelinTooltip }}"
                (click)="displayTooltip(excludesMichelinTooltip)"
                >info</mat-icon
              >
            </div>
          </div>
        </ng-container>
      </div>
      <!-- Step one close -->

      <!-- Step 2 -->
      <ng-container *ngIf="step == 2">
        <div>
          <div class="title">
            Clustering Conditions
            <mat-icon
              onkeypress=""
              [matTooltipDisabled]="true"
              matTooltipClass="material-tooltip"
              #clusteringsTooltip="matTooltip"
              matTooltip="{{ clusteringPageTooltip }}"
              (click)="displayTooltip(clusteringsTooltip)"
              >info</mat-icon
            >
          </div>
          <div class="subtitle">
            Choose multiple conditions as per the requirement.
          </div>

          <div class="checkbox_upload">
            <div id="checkboxes" class="clustering_checkboxes">
              <label
                class="cluster_name"
                style="pointer-events: all"
                title="{{ cluestring.description }}"
                *ngFor="let cluestring of clusterings; let i = index"
                [ngClass]="cluestring.isDefaultSelected ? 'disabled' : ''"
                formArrayName="clustering"
              >
                <input
                  type="checkbox"
                  [value]="cluestring.parameterName"
                  name="{{ cluestring.parameterName }}"
                  (change)="
                    handleClusterValues($event, cluestring.parameterLabel)
                  "
                  [checked]="cluestring.isSelected"
                  required
                />
                <div class="cluster_title">
                  <span class="cluster_label">{{
                    cluestring.parameterName
                  }}</span>
                </div>
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="vl_clustering"></div>
          </div>
        </div>
      </ng-container>
      <!--Step 2 end -->

      <!-- Step 3 start Degradation-->
      <ng-container *ngIf="step == 3">
        <div>
          <div class="title">
            Degradation Selection
            <mat-icon
              onkeypress=""
              [matTooltipDisabled]="true"
              matTooltipClass="material-tooltip"
              #degradationsTooltip="matTooltip"
              matTooltip="{{ degradationPageTooltip }}"
              (click)="displayTooltip(degradationsTooltip)"
              >info</mat-icon
            >
          </div>
          <div class="subtitle">
            Choose multiple conditions as per the requirement.
          </div>
          <!-- isButtonDisabledDeg{{ isButtonDisabledDeg }} -->
          <div
            class="validation"
            *ngIf="
              multiStepForm.controls['degradation'].invalid &&
              isDegradationSelected
            "
          >
            <img
              src="../../../../assets/images/warning.svg"
              alt="warning-icon"
            /><span
              >Please select at least one parameter for degradation and upload
              the degradation rule sheet to proceed ahead.</span
            >
          </div>

          <div class="checkbox_upload">
            <div id="checkboxes" class="clustering_checkboxes">
              <label
                class="cluster_name"
                *ngFor="let degradation of degradations; let i = index"
                [ngClass]="
                  intersection.includes(degradation.parameterName)
                    ? 'cluster_selected'
                    : '' || Loadintersection.includes(degradation.parameterName)
                    ? 'cluster_selected'
                    : ''
                "
                formArrayName="degradation"
                title="{{ degradation.description }}"
              >
                <input
                  type="checkbox"
                  [value]="degradation.parameterName"
                  name="{{ degradation.parameterName }}"
                  (change)="
                    handleDegradationValues($event, degradation.parameterLabel)
                  "
                  [checked]="degradation.isSelected"
                  required
                />
                <div class="cluster_title">
                  <span class="cluster_label">{{
                    degradation.parameterName
                  }}</span>
                </div>
                <span class="checkmark"></span>
              </label>
            </div>

            <div
              class="vl_degradation"
              [ngClass]="isDownloadBoxDisabled ? 'v1_line' : ''"
            ></div>
            <div class="degradation_div" *ngIf="isDownloadBoxDisabled">
              <div class="title">Your template is ready to be downloaded.</div>
              <div class="subtitle">
                Note that when you download the template, here you need to
                upload the file in order to proceed further.
              </div>
              <div class="download_file_section">
                <div>
                  <div class="file_name">Degradation_Rule_Sheet.xlsx</div>
                </div>
                <div onKeyPress="" (click)="downloadDegradationFile()">
                  <img
                    class="download-icon"
                    src="../../assets/images/download.svg"
                    alt="download_icon"
                  />
                </div>
              </div>

              <div class="upload_div upload_progress">
                <!-- checking file drop -->
                <div class="center1">
                  <ngx-file-drop
                    dropZoneLabel="Drop files here"
                    [multiple]="false"
                    (onFileDrop)="droppedDegradationFile($event)"
                    (onFileOver)="fileOver($event)"
                    (onFileLeave)="fileLeave($event)"
                  >
                    <ng-template
                      ngx-file-drop-content-tmp
                      let-openFileSelector="openFileSelector"
                    >
                      <span
                        class="upload-custom-text"
                        onKeyPress=""
                        (click)="openFileSelector()"
                        >Upload<br />
                        <span class="drag-font">(Drag & Drop File)</span></span
                      >
                    </ng-template>
                  </ngx-file-drop>
                </div>
                <!-- end -->
              </div>

              <div class="progress_div" *ngIf="successMessageDeg">
                <div class="progress">
                  <div
                    class="progress-bar active"
                    aria-valuenow="40"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="width: 100%"
                  ></div>
                </div>
                <div>100%</div>
              </div>

              <div class="file_name_div">
                {{ getFileName }}
              </div>
              <div class="file_name_div" *ngIf="getFileSize">
                <div class="file_size">
                  Size:
                  <span>{{ getFileSize | formatFileSize : true }}</span>
                </div>
              </div>
              <div class="validation" *ngIf="errorFlagDeg">
                <img
                  src="../../assets/images/warning.svg"
                  alt="warning-icon"
                /><span>{{ degradationError }}</span>
              </div>
              <div class="success_msg" *ngIf="successMessageDeg">
                <img
                  src="../../assets/images/sucess-icon.svg"
                  alt="success-icon"
                  width="18px"
                  height="18px"
                />
                Uploaded Successfully
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- step 3 end -->

      <!-- Step 4 start sorting & Input -->
      <ng-container *ngIf="step == 4">
        <div>
          <div class="title">
            Sorting &amp; Inputs
            <mat-icon
              onkeypress=""
              [matTooltipDisabled]="true"
              matTooltipClass="material-tooltip"
              #sortingInputTooltip="matTooltip"
              matTooltip="{{ sortingPageTooltip }}"
              (click)="displayTooltip(sortingInputTooltip)"
              >info</mat-icon
            >
          </div>
          <div class="subtitle">
            Please fill in the details below. Fields marked with
            <span class="mandatory_star">*</span> are mandatory.
          </div>

          <div
            class="validation"
            *ngIf="
              (multiStepForm.controls['priceType'].invalid &&
                isPriceTypeValidation &&
                !isPriceTypeDisable) ||
              (multiStepForm.controls['orderPreference'].invalid &&
                isOrderPreferenceValidation &&
                !isOrderPrefDisable) ||
              (multiStepForm.controls['priceDate'].invalid &&
                isPriceDateValidation &&
                !isPriceMonthDisable)
            "
          >
            <img
              src="../../../../assets/images/warning.svg"
              alt="warning_icon"
            /><span
              >Please fill in the required details to proceed further.</span
            >
          </div>
          <div class="sorting_dropdowns_div">
            <div class="price_date_div dropdown_div">
              <div
                class="title"
                [ngClass]="{
                  'red-border-class':
                    multiStepForm.controls['priceDate']?.errors &&
                    isPriceDateValidation &&
                    !isPriceMonthDisable
                }"
              >
                Price Month<span
                  class="mandatory_star"
                  *ngIf="!isPriceMonthDisable"
                  >*
                </span>
                <mat-icon
                  onkeypress=""
                  [matTooltipDisabled]="true"
                  matTooltipClass="material-tooltip"
                  #priceMonthsTooltip="matTooltip"
                  matTooltip="{{ priceMonthTooltip }}"
                  (click)="displayTooltip(priceMonthsTooltip)"
                  >info</mat-icon
                >
              </div>
              <div
                class="dropdown calendar"
                [ngClass]="{
                  'price-month-border':
                    multiStepForm.controls['priceDate']?.errors &&
                    isPriceDateValidation
                }"
              >
                <input
                  type="text"
                  readonly="true"
                  placeholder="Select Price Month"
                  [(ngModel)]="priceDate"
                  formControlName="priceDate"
                  onKeyPress=""
                  (click)="onMonthPickerOpen('monthRange')"
                  [ngClass]="{ open_btn: true, disabled: isPriceMonthDisable }"
                />
                <div
                  class="calendar-svg"
                  onKeyPress=""
                  (click)="onMonthPickerOpen('monthRange')"
                >
                  <img
                    src="../../../../assets/images/calendar.svg"
                    alt="calendar"
                  />
                </div>
                <month-picker
                  (monthRangeSelected)="logRange($event)"
                  *ngIf="isMonthPickerVisible"
                ></month-picker>
              </div>
            </div>

            <div class="price_type_div dropdown_div">
              <div
                class="title"
                [ngClass]="{
                  'red-border-class':
                    multiStepForm.controls['priceType']?.errors &&
                    isPriceTypeValidation &&
                    !isPriceTypeDisable
                }"
              >
                Price Type<span
                  class="mandatory_star"
                  *ngIf="!isPriceTypeDisable"
                  >*
                </span>
                <mat-icon
                  onkeypress=""
                  [matTooltipDisabled]="true"
                  matTooltipClass="material-tooltip"
                  #priceTypesTooltip="matTooltip"
                  matTooltip="{{ priceTypeTooltip }}"
                  (click)="displayTooltip(priceTypesTooltip)"
                  >info</mat-icon
                >
              </div>
              <div
                class="dropdown"
                [ngClass]="{
                  'red-border-classs':
                    multiStepForm.controls['priceType']?.errors &&
                    isPriceTypeValidation &&
                    !isPriceTypeDisable
                }"
              >
                <ng-multiselect-dropdown
                  [ngClass]="{
                    'product_line_div.title':
                      multiStepForm.controls['priceType']?.errors &&
                      isPriceTypeValidation
                  }"
                  [placeholder]="'Select Price Type'"
                  [settings]="priceTypeDropdownSettings"
                  [(ngModel)]="selectedPriceType"
                  [ngModelOptions]="{ standalone: true }"
                  formArrayName="priceType"
                  [data]="priceTypes"
                  (onSelect)="onPriceTypeSelect($event)"
                  (onDeSelect)="onPriceTypeDeselect($event)"
                  (onSelectAll)="onSelectAllPriceTypes($event)"
                  (onDeSelectAll)="onDeSelectAllPriceType()"
                  [disabled]="isPriceTypeDisable"
                  [required]="!isPriceTypeDisable"
                >
                  {{ priceTypes }}
                </ng-multiselect-dropdown>
              </div>
            </div>

            <div class="order_div dropdown_div">
              <div
                class="title"
                [ngClass]="{
                  'red-border-class':
                    multiStepForm.controls['orderPreference']?.errors &&
                    isOrderPreferenceValidation &&
                    !isOrderPrefDisable
                }"
              >
                Order Preference
                <span class="mandatory_star" *ngIf="!isOrderPrefDisable"
                  >*
                </span>
                <mat-icon
                  onkeypress=""
                  [matTooltipDisabled]="true"
                  matTooltipClass="material-tooltip"
                  #orderPrefrenceTooltip="matTooltip"
                  matTooltip="{{ orderPrefTooltip }}"
                  (click)="displayTooltip(orderPrefrenceTooltip)"
                  >info</mat-icon
                >
              </div>
              <div
                class="dropdown"
                [ngClass]="{
                  'red-border-classs':
                    multiStepForm.controls['orderPreference']?.errors &&
                    isOrderPreferenceValidation &&
                    !isOrderPrefDisable
                }"
              >
                <ng-multiselect-dropdown
                  [ngClass]="{
                    'product_line_div.title':
                      multiStepForm.controls['orderPreference']?.errors &&
                      isOrderPreferenceValidation
                  }"
                  [placeholder]="'Select Order Preference'"
                  [settings]="orderPreferenceDropdownSettings"
                  [(ngModel)]="selectedOrderPreference"
                  [ngModelOptions]="{ standalone: true }"
                  formArrayName="orderPreference"
                  [data]="orderPreferences"
                  (onSelect)="onOrderPreferenceSelect($event)"
                  (onDeSelect)="onOrderPreferenceDeselect($event)"
                  (onSelectAll)="onSelectAllOrderPreferences($event)"
                  (onDeSelectAll)="onDeSelectAllOrderPreferences()"
                  [disabled]="isOrderPrefDisable"
                  [required]="!isOrderPrefDisable"
                >
                  {{ orderPreferences }}
                </ng-multiselect-dropdown>
              </div>
            </div>
            <div class="valid_from_date_div dropdown_div">
              <div
                class="title"
                [ngClass]="{
                  'red-border-class':
                    multiStepForm.controls['validFromDateValue']?.errors &&
                    isValidFromDateValidation &&
                    !isValidFromDateDisable
                }"
              >
                Pairing Valid From Date<span
                  class="mandatory_star"
                  *ngIf="!isValidFromDateDisable"
                  >*
                </span>
                <mat-icon
                  onkeypress=""
                  [matTooltipDisabled]="true"
                  matTooltipClass="material-tooltip"
                  #validFromTooltip="matTooltip"
                  matTooltip="{{ validFromDateToolTip }}"
                  (click)="displayTooltip(validFromTooltip)"
                  >info</mat-icon
                >
              </div>
              <div
                class="dropdown calendar"
                [ngClass]="{
                  'price-month-border':
                    multiStepForm.controls['validFromDateValue']?.errors &&
                    isValidFromDateValidation
                }"
              >
                <input
                  type="text"
                  readonly="true"
                  placeholder="Select Valid From Date"
                  [(ngModel)]="validFromDateValue"
                  formControlName="validFromDateValue"
                  onKeyPress=""
                  (click)="onMonthPickerOpen('valid_from_date')"
                  [ngClass]="{
                    open_btn: true,
                    disabled: isValidFromDateDisable
                  }"
                />
                <div
                  class="calendar-svg"
                  onKeyPress=""
                  (click)="onMonthPickerOpen('valid_from_date')"
                >
                  <img
                    src="../../../../assets/images/calendar.svg"
                    alt="calendar"
                  />
                </div>
                <month-picker
                  (monthRangeSelected)="logRange2($event)"
                  *ngIf="isValidFromDateVisible"
                ></month-picker>
              </div>
            </div>
          </div>


          <div class="sorting_dropdowns_div">
            <div class="input_div">
              <label class="checkbox_div"
                >Competitor Prioritization Required

                <input
                  type="checkbox"
                  [checked]="isCompetitorChecked"
                  [(ngModel)]="isCompetitorChecked"
                  (change)="onChecked($event)"
                  formControlName="competitorPrioritization"
                />
                <span class="checkmark"></span>
              </label>
              <mat-icon
                onkeypress=""
                [matTooltipDisabled]="true"
                matTooltipClass="material-tooltip"
                #compitiorFileTooltip="matTooltip"
                matTooltip="{{ compPriorTooltip }}"
                (click)="displayTooltip(compitiorFileTooltip)"
                >info</mat-icon
              >
              <ng-container *ngIf="isCompetitorChecked">
                <br>
                <div class="subtitle" style="margin-left:40px">
                  Please note: The values available for the below fields are
                      based on the selected “Passanger Cars” product line.
                      <br />
                      You can change the product line on Step 1 if you are
                      unable to find a specific brand/tireline.
                </div>
                <div class="sortingPage contentCard">
                  <div class="flexColumnBox">
                    <div class="competitionCont">
                      <div class="cardsCont">
                        <div class="prioritisationCont">
                          <div class="formGroup">
                            <p></p>
                            <div class="formControl">
                              <label for="selectModules"> Michelin Brands </label>
                            </div>
                            <div class="formControl">
                              <label for="selectModules"> Michelin Tireline </label>
                            </div>
                            <div class="connection" style="visibility: hidden;">
                              <div class="left"></div>
                              <div class="right"></div>
                            </div>
                            <div class="formControl">
                              <label for="selectModules"> Competitor Brands </label>
                            </div>
                            <div class="formControl">
                              <label for="selectModules">
                                Competitor Tireline
                              </label>
                            </div>
                          </div>
                          
                          <div class="formGroup" *ngFor="let dynamic of dynamicArray; let i = index;">
                            <p>{{i+1}}.</p>
                            <div class="formControl">
                              <ng-multiselect-dropdown
                              [placeholder]="'Michelin Brands'"
                              [settings]="compBrandsDropdownSettings"
                              [(ngModel)]="dynamicArray[i].michelinBrand"
                              [ngModelOptions]="{ standalone: true }"
                              (onSelect)="validateCompRow(i)"
                              (onDeSelect)="validateCompRow(i)"
                              [data]="michelinBrands">
                              {{ michelinBrands }}
                            </ng-multiselect-dropdown>
                            </div>
      
                            <div class="formControl">
                              <ng-multiselect-dropdown
                              [placeholder]="'Michelin Tirelines'"
                              [settings]="compBrandsDropdownSettings"
                              [(ngModel)]="dynamicArray[i].michelinTireLine"
                              [ngModelOptions]="{ standalone: true }"
                              (onSelect)="validateCompRow(i)"
                              (onDeSelect)="validateCompRow(i)"
                              [data]="michelinBrands">
                              {{ michelinBrands }}
                            </ng-multiselect-dropdown>
                            </div>
                            <div class="connection">
                              <div class="left con{{i}}" ></div>
                              <div class="right con{{i}}"></div>
                            </div>
                            <div class="formControl">
                              <ng-multiselect-dropdown
                              [placeholder]="'Other Tirelines'"
                              [settings]="compBrandsDropdownSettings"
                              [(ngModel)]="dynamicArray[i].otherBrand"
                              (onSelect)="validateCompRow(i)"
                              (onDeSelect)="validateCompRow(i)"
                              [ngModelOptions]="{ standalone: true }"
                              [data]="otherBrands">
                              {{ otherBrands }}
                            </ng-multiselect-dropdown>
                            </div>
                            <div class="formControl">
                              <ng-multiselect-dropdown
                              [placeholder]="'Other Tirelines'"
                              [settings]="compBrandsDropdownSettings"
                              [(ngModel)]="dynamicArray[i].otherTireLine"
                              (onSelect)="validateCompRow(i)"
                              (onDeSelect)="validateCompRow(i)"
                              [ngModelOptions]="{ standalone: true }"
                              [data]="otherBrands">
                              {{ otherBrands }}
                            </ng-multiselect-dropdown>
                            </div>
                            <button class="deleteBtn" (click)="deleteRow(i)">
                              <img
                                [src]="deleteIconBtn"
                                alt="delete"
                                class="deleteIconBtn"
                              />
                            </button>
                          </div>

                          <div class="addMoreCont">
                            <label for="moreRows"> Add more rows: </label>
                            <input type="number" #addRowValue id="moreRows" value="1" name="addRowValue" />
                            <button class="primaryBtn blue" (click)="addRow(addRowValue);">Add</button>
                          </div>
                          <div class="errorCont"  *ngIf="compErrorArray.length > 0">
                            <img [src]="errorIcon" alt="" />
                            <div class="errorMessages">
                              <div *ngFor="let compError of compErrorArray; let i = index">
                                <p>{{compError}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </ng-container>
            </div>
          </div>
      </div>

        <div class="appContainer">
          <div class="wrpr">
          
          </div>
        </div>
      </ng-container>
      <!-- Step 4 end -->

      <div class="btns_div">
        <div class="btns_left">
          <ng-container *ngIf="step >= 2">
            <button
              type="button"
              class="secondary_green_btn"
              onKeyPress=""
              (click)="goBack()"
            >
              Back
            </button>
          </ng-container>
          <button
            type="button"
            class="tertiary_green_btn"
            onKeyPress=""
            (click)="clearAction()"
          >
            Clear All
          </button>
        </div>
        <div class="btns_right">
          <button
            type="submit"
            class="tertiary_green_btn"
            onKeyPress=""
            (click)="onSaveDraft(true)"
            [disabled]="
              step == 1 &&
              (!multiStepForm.controls['productLine'].valid ||
                !multiStepForm.controls['region'].valid ||
                !multiStepForm.controls['businessLine'].valid ||
                isButtonDisabled)
            "
          >
            Save
          </button>
          <button
            type="submit"
            class="primary_green_btn"
            *ngIf="step == 1"
            onKeyPress=""
            (click)="goNext()"
            [disabled]="
              !multiStepForm.controls['productLine'].valid ||
              !multiStepForm.controls['region'].valid ||
              !multiStepForm.controls['businessLine'].valid ||
              isButtonDisabled
            "
          >
            Next
          </button>
          <button
            type="submit"
            class="primary_green_btn"
            *ngIf="step == 2"
            onKeyPress=""
            (click)="goNext()"
          >
            Next
          </button>

          <button
            type="submit"
            class="primary_green_btn"
            *ngIf="step == 3"
            onKeyPress=""
            (click)="goNext()"
            [disabled]="
              !multiStepForm.controls['degradation'].valid ||
              isDegradationSelected ||
              isButtonDisabledDeg
            "
          >
            Next
          </button>

          <button
            type="submit"
            class="primary_green_btn"
            *ngIf="step >= 4"
            onKeyPress=""
            (click)="onSubmit()"
            id="liveToastBtn"
            [disabled]="
              (!multiStepForm.get('priceType').valid && !isPriceTypeDisable) ||
              (!multiStepForm.get('orderPreference').valid &&
                !isOrderPrefDisable) ||
              (!multiStepForm.get('priceDate').valid && !isPriceMonthDisable) ||
              (!multiStepForm.get('validFromDateValue').valid &&
                !isValidFromDateDisable) ||
              (multiStepForm.get('competitorPrioritization').value &&
                !successMessageComp)
            "
          >
            Submit
          </button>
        </div>
      </div>
    </form>
    <!--<pre>{{ multiStepForm.dirty }}</pre>
    <pre>{{ multiStepForm.value | json }}</pre> -->
  </div>
</div>

<!-- Modal window container starts -->
<div
  class="modal modal-backdrop"
  *ngIf="isConfirmationPopupVisibale"
  tabindex="-1"
  [ngStyle]="{ display: displayStyle }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">Are you sure?</div>
        <div>
          <img
            src="../../../../assets/images/close_icon.svg"
            class="close_icon"
            width="16px"
            height="16px"
            alt="icon"
            onKeyPress=""
            (click)="onCancelThroughIcon()"
          />
        </div>
      </div>
      <div class="modal-body">
        <p>
          {{ popupMessage }}
        </p>
        <div class="spacer16"></div>
        <p></p>
      </div>
      <div class="modal-footer flexSP">
        <button
          type="button"
          class="secondary_green_btn"
          onKeyPress=""
          (click)="onCancel()"
        >
          No
        </button>
        <button
          type="button"
          class="primary_green_btn"
          onKeyPress=""
          (click)="actionOnConfirmation()"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<!-- download unmatched CAI list popup design-->
<!-- Modal window container starts -->
<div
  class="modal modal-backdrop"
  *ngIf="isMissingCAIDownloadPopup"
  tabindex="-1"
  [ngStyle]="{ display: displayStyle }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <p>
          {{ popupMessage }}
        </p>
      </div>
      <div class="modal-footer flexSP">
        <button
          type="button"
          class="primary_green_btn"
          onKeyPress=""
          (click)="caiFileDownload()"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</div>

<app-toast
  [message]="isToastMsg"
  *ngIf="isShowErrorMessageWithToast"
></app-toast>

<ng-container *ngIf="isSubmitedSuccessfully">
  <div id="overlay"></div>
  <div class="toster-box">
    <img
      src="../../assets/images/group-3.svg"
      width="48px"
      height="48px"
      alt="Not Fount"
      class="success-icon custom-align"
    />
    <div class="submitted-message-box">
      <span class="success-text">Submitted Successfully!</span>
      <span class="pairing-id-text">Pairing Request Id: {{ pairingId }} </span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="showBoolean">
  <div id="overlay"></div>
  <div class="toster-box">
    <span class="success-text error-text">{{ getSharingErorr }}</span>
  </div>
</ng-container>

<ng-container *ngIf="isShowError">
  <div id="overlay"></div>
  <div class="toster-box error-toster-box">
    <img src="../../assets/images/warning-icon.svg" alt="warning_icon" />
    <span class="success-text1 error-text1">{{ getSharingErorr }}</span>
  </div>
</ng-container>
