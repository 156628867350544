import { Component, OnInit, ViewChild } from '@angular/core';
import * as PairingConst from 'src/app/pairing.constants';
import * as PairingHelper from './pairing-step-form-helper.service';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  FormArray,
  Validators,
} from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PairingStepFormApi } from './pairing-step-form.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from '../../services/utils.service';
import * as FileSaver from 'file-saver';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DownloadFileService } from '../../services/downloadFile.service';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-pairing-step-form',
  templateUrl: './pairing-step-form.component.html',
  styleUrls: ['./pairing-step-form.component.scss'],
})
export class PairingStepFormComponent implements OnInit {

  @ViewChild("perimeterTooltip") perimeterTooltip: MatTooltip;
  @ViewChild("regionsTooltip") regionsTooltip: MatTooltip;
  @ViewChild("countrysTooltip") countrysTooltip: MatTooltip;
  @ViewChild("plsTooltip") plsTooltip: MatTooltip;
  @ViewChild("blsTooltip") blsTooltip: MatTooltip;
  @ViewChild("michelinBrandsTooltip") michelinBrandsTooltip: MatTooltip;
  @ViewChild("otherBrandsTooltip") otherBrandsTooltip: MatTooltip;
  @ViewChild("excludesMichelinTooltip") excludesMichelinTooltip: MatTooltip;
  @ViewChild("caiFileTooltip") caiFileTooltip: MatTooltip;
  @ViewChild("clusteringsTooltip") clusteringsTooltip: MatTooltip;
  @ViewChild("degradationsTooltip") degradationsTooltip: MatTooltip;
  @ViewChild("sortingInputTooltip") sortingInputTooltip: MatTooltip;
  @ViewChild("priceMonthsTooltip") priceMonthsTooltip: MatTooltip;
  @ViewChild("priceTypesTooltip") priceTypesTooltip: MatTooltip;
  @ViewChild("orderPrefrenceTooltip") orderPrefrenceTooltip: MatTooltip;
  @ViewChild("compitiorFileTooltip") compitiorFileTooltip: MatTooltip;
  @ViewChild("validFromTooltip") validFromTooltip: MatTooltip;
  step = 0;
  multiStepForm!: FormGroup;
  list: any;
  submitted: boolean = false;
  validFromDateFlag: boolean = false;
  priceDateFlag: boolean = false;

  brands: any = [];

  regions: any = [];
  countries: any = [];
  businessLines: any = [];
  productLines: any = [];
  michelinBrands: any = [];
  otherBrands: any = [];
  otherSeasons: any = [];
  otherTyreUsages: any = [];
  otherTirelines: any = [];
  seasons: any = [];
  tyreUsages: any = [];
  tyreLines: any = [];

  clusterings: any = [];
  degradations: any = [];

  priceTypes: any = [];
  orderPreferences: any = [];

  masterdata: any;
  otherMasterData: any;

  selectedCountries: any = [];
  selectedBusinessLine: any = [];
  selectedProductLine: any = [];
  selectedMichelinBrand: any = [];
  selectedOtherBrand: any = [];
  selectedOtherSeason: any = [];
  selectedOtherTyreUsage: any = [];
  selectedOtherTireline: any = [];
  selectedSeason: any = [];
  selectedTyreUsage: any = [];
  selectedTyreLine: any = [];
  selectedClusteringValues: any = [];
  selectedPriceType: any = [];
  selectedOrderPreference: any = [];
  selectedProductLineId: any;

  alllist: any = [];
  otherAlllist: any = [];

  countryDropdownSettings: IDropdownSettings = {};
  businessLineDropdownSettings: IDropdownSettings = {};
  productLineDropdownSettings: IDropdownSettings = {};
  michelinBrandDropdownSettings: IDropdownSettings = {};
  otherBrandDropdownSettings: IDropdownSettings = {};
  otherSeasonDropdownSettings: IDropdownSettings = {};
  otherTyreUsageDropdownSettings: IDropdownSettings = {};
  otherTirelineDropdownSettings: IDropdownSettings = {};
  seasonDropdownSettings: IDropdownSettings = {};
  michelinTyreUsageDropdownSettings: IDropdownSettings = {};
  michelinTyreLineDropdownSettings: IDropdownSettings = {};
  priceTypeDropdownSettings: IDropdownSettings = {};
  orderPreferenceDropdownSettings: IDropdownSettings = {};

  //params: any;
  storeRegionId: any = null;
  storeRegionCode: string = null;
  getRegions: any = {};
  itemsRemoved: any;
  typeSelected: string;
  getBrandIds: any;
  getOtherBrandIds: any;
  getSeasons: any;
  getUsages: any;
  getOtherUsages: any;
  getAllsubSeasonIdsIn: any;
  getAllOthersubSeasonIdsIn: any;
  getAllusageIdsIn: any;
  getAllOtherusageIdsIn: any;

  getAllBrandIds: any = [];
  getAllselectedSeason: any = [];
  getAllOtherBrandIds: any = [];
  getAllOtherSelectedSeason: any = [];

  isSeasonDisabled: boolean = true;
  isTireUsageDisabled: boolean = true;
  isTireLineDisabled: boolean = true;

  isOtherSeasonDisabled: boolean = true;
  isOtherTireUsageDisabled: boolean = true;
  isOtherTireLineDisabled: boolean = true;

  stepLabelText: string = 'Clustering';

  getSelectedClusteringValues: any = [];
  getSelectedDegradationValues: any = [];

  intersection: any = [];
  Loadintersection: any = [];
  fileToUpload: File = null;

  file: any;
  getFileName: string = null;
  getFileSize: any = null;

  getFileNameCompetitor: string = null;
  getFileSizeCompetitor: any = null;

  getCAIFileName: string = null;
  getCAIFileSize: any = null;

  message: boolean = false;
  showMessage: boolean = false;
  errorFlag: boolean = false;
  error: any = null;
  //errorMessage: boolean = false;
  isDownloadBoxDisabled: boolean = false;

  clusteringData: any;
  degradationData: any;
  fileName = '';
  fileSelectText: string = 'Upload';
  isCompetitorChecked = false;
  isCAIChecked: boolean = false;
  isMichelinBrandsExcluded: boolean = false;

  public files: NgxFileDropEntry[] = [];
  isButtonDisabled: boolean = false;

  isMonthPickerVisible = false;
  isValidFromDateVisible = false;

  degradationError: string = null;
  errorFlagDeg: boolean = false;
  successMessageDeg: boolean = false;

  competitorError: string = null;
  errorFlagComp: boolean = false;
  successMessageComp: boolean = false;

  caiError: string = null;
  errorFlagCAI: boolean = false;
  successMessageCAI: boolean = false;

  priceDate: string = null;

  seasonitems: any = [];
  otherSeasonitems: any = [];

  successDegradationFile: string = '';
  successCompetitorFile: string = '';

  successCAIFile: string = '';

  startDate: string = '';
  endDate: string = '';
  validStartDate: string = '';

  isSubmitedSuccessfully: boolean = false;
  isSavedSuccessfully: boolean = false;

  backupCountryValue: any = [];
  countrySelection: any = [''];

  contentDispositionHeader: string = '';

  isBusinessLineSelected: boolean = false;
  isProductLineSelected: boolean = false;
  isDegradationSelected: boolean = false;
  isPriceTypeValidation: boolean = false;
  isOrderPreferenceValidation: boolean = false;
  isPriceDateValidation: boolean = false;
  isValidFromDateValidation: boolean = false;
  pairingId: any;

  productLineId: any;
  productLineIdOnEdit: any;
  isCountryDisabledOnRegion: boolean = true;
  isRegionSelected: boolean = false;
  CAIListMessage: string = 'CAI List file uploaded successfully';
  isCAIFileMissing: boolean = false;
  isMissingCAIDownloadPopup: boolean = false;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  getDegradationCheckValues: any[];
  ShowDatePicker = true;

  formSubmitted = false;
  isShow: boolean = false;
  errM: any;
  showBoolean: any;
  getSharingErorr: any;
  isMichelinBrandDisabled: boolean = false;
  isButtonDisabledDeg: boolean = false;

  id: any;
  editMode = false;
  stage: string;
  getDraftRecordById: any;
  maxStep: number;
  isDraftFile: boolean;
  timeInterval: string;
  isSaveDraftPopupShow: boolean = false;
  displayStyle = 'none';
  isToastMsg: any = '';
  isShowErrorMessageWithToast: boolean = false;
  isClickAction: boolean = false;
  updatedDate: any;
  isSaved: boolean = false;
  isMultiStepFormDirty: boolean = false;
  isConfirmationPopupVisibale: boolean = false;
  CLEARALL = 'clearall';
  SAVEDRAFT = 'SAVEDRAFT';
  UNMATCHED_CAI_DOWNLOAD = 'unmatchedCAIDownload';
  currentActionType: any;
  popupMessage: any;
  landHome: boolean = false;
  isClearAllOnPerimeter: boolean = false;
  priceTypeParameterLabel: any;
  orderPreferenceParameterLabel: any;
  isShowError: boolean = false;
  isOrderPrefDisable: boolean = false;
  isPriceMonthDisable: boolean = false;
  isPriceTypeDisable: boolean = false;
  //isCountryClusteringHidden: boolean = false;
  productLinesParameterLabel: any;
  isProductLineDisabledOnRegion: boolean = true;
  isBusinessLineDisabledOnProductLine: boolean = true;
  isMacroUsageSelected: boolean = false;
  userId: string;
  perimeterPageTooltip: string ='The Perimeter page defines the data to be considered for Pairing Algorithm. Pairing data can be filtered using the various filters provided or by uploading the CAI List.';
  regionTooltip: string ='In region filter user can select for which region user wants to generate the pairing. User can select only one region at a time';
  countryTooltip: string ='Country filter shows the countries available as per selected region and allows user to select any number of countries for which user wants to generate pairing';
  plTooltip: string ='Product Line filter shows the product lines available as per selected region and user rights provided. It allows user to select any one of the product line for which user wants to generate pairing';
  blTooltip: string ='Business Line filter shows the business lines available as per selected Region and Product Line. It allows user to select any one of the business line for which user wants to generate pairing';
  michelinBrandTooltip: string ='These filters are available as per the selected product line. Michelin Brand filters shows data only for Michelin brands and are applicable to select/filter the data for Michelin Brands for which user wants to generate the pairing. \r\nNote: These filters will be disabled in case user selects the CAI Upload option';
  compitiorTooltip: string ='These filters are available as per the selected product line. Other Brand filters contains data for both Competitor and Michelin Brands. \r\nNote: Michelin Brands will be removed from the filters when user selects the "Exclude Michelin Brands" checkbox';
  caiTooltip: string ='This functionality allows user to upload the list of CAIs for which user wants to generate the pairing. These CAIs needs to be uploaded in the provided template only. \r\nNote: CAI List functionality is only applicable for Michelin Brands. Michelin Brands filters will be disabled in case user selects the CAI Upload option';
  excludeMichelinTooltip: string = 'This functionality enables user to exclude michelin brands from Other Brands selection';

  clusteringPageTooltip: string ='The clustering is the first step in the pairing rules definition. Clustering defines the parameters which needs to be exactly matched. This means Michelin and the Competitor product would have the same value for the selected clustering parameters'
  degradationPageTooltip: string ='The Degradation is the Second step in the pairing rules definition. Through degrdation parameters user can define the others required parameters to generate pairing. The degradation rules would be used to generate the exact match as well as the similar matched based on the user defined scores for degradation rules.';
  priceMonthTooltip: string ='Period of prices collected the user wants to take into account to generate pairing.';
  sortingPageTooltip: string ='In this final step user can define the type and duration of pricing data to be integrate to generate the pairing result. User can also define Competitor Tirelines to pair in priority';
  priceTypeTooltip: string ='User can define which prices to be considered to generate the pairing';
  orderPrefTooltip: string ='The objective is to define a priorization in case there is multiple pairing proposals for a given competitor with the same score. Most popular will focus on product which is the most present on web sites - Most recent will focus on newest product.';
  compPriorTooltip: string ='Through this functionality user can define per Michelin tirelines what are the competitors tirelines to pair in priority.';
  validFromDateToolTip: string ="This is an user defined field to set the start month for the validity of Pairing. This month can be set as per the user needs and would be available in the 'PriceFx Perimeter' output file";
  isValidFromDateDisable: any;
  valid_from_date: string = null;
  validFromDateValue: string = null;

  constructor(
    private readonly pairingStepFormApi: PairingStepFormApi,
    private readonly spinnerService: NgxSpinnerService,
    private readonly utilService: UtilService,
    private readonly http: HttpClient,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly downloadFileService: DownloadFileService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.typeSelected = 'ball-clip-rotate-pulse';
  }

  ngOnInit(): void {
    this.utilService.getUserId.subscribe((id: any) => {
      if (id == null || id == '') {
        this.userId = sessionStorage.getItem('userId');
      } else {
        this.userId = id as string;
      }
    });

    // loading spinner show
    this.spinnerService.show();

    this.isSaved = false;

    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.editMode = params['id'] != null;
    });

    // on error
    this.pairingStepFormApi.error.subscribe((errorMessage: any) => {
      this.error = errorMessage;
    });

    this.utilService.showPopup.subscribe((res: any) => {
      this.showBoolean = res;
    });

    this.utilService.SharingData.subscribe((res: any) => {
      this.getSharingErorr = res;
    });

    this.utilService.tosterMessage.subscribe((res: any) => {
      this.isToastMsg = res;
    });

    // regions call
    this.pairingStepFormApi
      .getRegions(
        this.userId,
        this.selectedBusinessLine,
        this.selectedProductLine
      )
      .subscribe(
        (res) => {
          const regionLsts = res;
          this.regions = regionLsts.result;
          this.regions = this.regions.filter(function (el: any) {
            return el !== 'NULL';
          });
          this.showBoolean = false;
        },
        (err) => {
          this.spinnerService.hide();
          this.showBoolean = true;
        }
      );

    this.getRegionDetails();
    if (!this.editMode) {
      this.getProductLineDetails([]);
      this.getBusinessLineDatails([], null);
    }

    //----Setting of form controls---------
    this.countryDropdownSettings = PairingConst.countryDropdownSettings;
    this.businessLineDropdownSettings =
      PairingConst.businessLineDropdownSettings;
    this.productLineDropdownSettings = PairingConst.productLineDropdownSettings;
    this.michelinBrandDropdownSettings =
      PairingConst.michelinBrandDropdownSettings;
    this.seasonDropdownSettings = PairingConst.seasonDropdownSettings;
    this.michelinTyreUsageDropdownSettings =
      PairingConst.michelinTyreUsageDropdownSettings;
    this.michelinTyreLineDropdownSettings =
      PairingConst.michelinTyreLineDropdownSettings;
    this.otherBrandDropdownSettings = PairingConst.otherBrandDropdownSettings;
    this.otherSeasonDropdownSettings = PairingConst.otherSeasonDropdownSettings;
    this.otherTyreUsageDropdownSettings =
      PairingConst.otherTyreUsageDropdownSettings;
    this.otherTirelineDropdownSettings =
      PairingConst.otherTirelineDropdownSettings;
    this.priceTypeDropdownSettings = PairingConst.priceTypeDropdownSettings;
    this.orderPreferenceDropdownSettings =
      PairingConst.orderPreferenceDropdownSettings;

    this.buildForm();

    if (this.editMode) {
      this.pairingStepFormApi.getDraftRecord(this.id).subscribe(
        (res: any) => {
          const responseData = res;
          this.getDraftRecordById = responseData.result;
          this.stage = this.getDraftRecordById?.stage;
          this.getTimeInterval();

          // country call
          if (this.editMode) {
            this.isProductLineDisabledOnRegion = false;
            this.isBusinessLineDisabledOnProductLine = false;
            this.storeRegionId = this.getDraftRecordById?.regionId;
            this.checkCountryWW(this.getDraftRecordById?.regionCode);

            this.storeRegionCode = this.getDraftRecordById?.regionCode;
            this.getProductLineDetails(this.getDraftRecordById?.regionCode);
            this.getBusinessLineDatails(
              this.getDraftRecordById?.regionCode,
              this.getDraftRecordById?.productLine[0]
            );
            this.fetchMichelinBrands();
            this.selectedMichelinBrand = PairingHelper.getSelectedRecords(
              this.michelinBrands,
              this.getDraftRecordById?.brandX ?? [],
              'brandName'
            );
            //----Loading of Other Brands based on isMichelinExcluded flag-----
            this.getOtherBrands(
              this.getDraftRecordById?.isMichelinExcluded,
              false
            );

            this.getParameterId(this.getDraftRecordById?.productLine[0]);

            this.pairingStepFormApi.getCountrys(this.storeRegionId).subscribe(
              (res) => {
                this.spinnerService.hide();
                const countryLists = res;
                this.countries = countryLists.result;
                this.countries = this.countries.filter(function (el: any) {
                  return el !== 'NULL';
                });

                this.step = new Map(
                  PairingConst.stepMap.map((i) => [
                    i.stage.toUpperCase(),
                    i.step,
                  ])
                ).get(this.stage.toUpperCase());
                this.stepLabelText = this.getCurrentStepLabelText(this.step);
                this.maxStep = this.step;
                this.checkCountryWW(this.storeRegionCode);
                this.productLineId = this.productLineIdOnEdit;
                this.getCAIFileName = this.getDraftRecordById?.caiTable;
                this.getFileName = this.getDraftRecordById?.ruleSheet;
                this.isCompetitorChecked =
                  this.getDraftRecordById?.competitorPrioritization;
                this.isCAIChecked = this.getDraftRecordById?.isCAI;
                this.getFileNameCompetitor =
                  this.getDraftRecordById?.competitorsPrioritizationTable;
                this.isMichelinBrandsExcluded =
                  this.getDraftRecordById?.isMichelinExcluded;

                if (
                  this.getFileName != null &&
                  this.getFileName != undefined &&
                  !this.isMultiStepFormDirty
                ) {
                  this.isDegradationSelected = false;
                  this.isButtonDisabledDeg = false;
                  this.successMessageDeg = true;
                  this.getFileSize = true;
                } else {
                  this.isButtonDisabledDeg = true;
                }

                if (
                  this.getFileNameCompetitor != null &&
                  this.getFileNameCompetitor != undefined &&
                  !this.isMultiStepFormDirty
                ) {
                  this.successMessageComp = true;
                  this.getFileSizeCompetitor = true;
                }

                if (
                  this.getCAIFileName != null &&
                  this.getCAIFileName != undefined &&
                  !this.isMultiStepFormDirty
                ) {
                  this.successMessageCAI = true;
                  this.getCAIFileSize = true;
                }

                //---Set MultiStepForm for array attributes with FormControls------
                this.multiStepForm = this.fillMultiStepForm(
                  PairingConst.multiStepFormArryAttr
                );

                //------Get selected values of Form Controls-----------
                this.selectedProductLine = PairingHelper.getSelectedRecords(
                  this.productLines,
                  this.getDraftRecordById?.productLine,
                  'code'
                );

                this.selectedBusinessLine = PairingHelper.getSelectedRecords(
                  this.businessLines,
                  this.getDraftRecordById?.businessLine,
                  'businessLineName'
                );

                this.selectedCountries = PairingHelper.getSelectedRecords(
                  this.countries,
                  this.getDraftRecordById?.country ?? [],
                  'countryNamePfx'
                );

                //-------perform Action from Clustering till Until Progress Level----
                this.clusteringAction();

                if (
                  this.getDraftRecordById?.regionCode === 'WW'
                ) {
                  this.applyRegionWW();
                }

                if (
                  this.getDraftRecordById?.pricePeriodStart != undefined &&
                  this.getDraftRecordById?.pricePeriodEnd != undefined
                ) {
                  this.priceDate =
                    PairingConst.monthNames[
                      this.getDraftRecordById?.pricePeriodStart.split('-')[1] -
                        1
                    ] +
                    ' ' +
                    this.getDraftRecordById?.pricePeriodStart.split('-')[0] +
                    ' - ' +
                    PairingConst.monthNames[
                      this.getDraftRecordById?.pricePeriodEnd.split('-')[1] - 1
                    ] +
                    ' ' +
                    this.getDraftRecordById?.pricePeriodEnd.split('-')[0];
                }
                if( this.getDraftRecordById?.valid_from_date != undefined ) {
                  this.validFromDateValue =
                  PairingConst.monthNames[
                    this.getDraftRecordById?.valid_from_date.split('-')[1] -
                      1
                  ] +
                  ' ' +
                  this.getDraftRecordById?.valid_from_date.split('-')[0];
                }
                
                this.multiStepForm.patchValue({
                  id: this.id,
                  region: this.getDraftRecordById?.region,
                  regionCode: this.getDraftRecordById?.regionCode,
                  productLine: this.getDraftRecordById?.productLine,
                  businessLine: this.getDraftRecordById?.businessLine,
                  isCAI: this.getDraftRecordById?.isCAI,
                  isMichelinExcluded:
                    this.getDraftRecordById?.isMichelinExcluded,
                  caiTable: this.getDraftRecordById?.caiTable,
                  ruleSheet: this.getDraftRecordById?.ruleSheet,
                  pricePeriodStart: this.getDraftRecordById?.pricePeriodStart,
                  pricePeriodEnd: this.getDraftRecordById?.pricePeriodEnd,
                  priceDate: this.priceDate,
                  mostPopular: this.getDraftRecordById?.mostPopular,
                  mostRecent: this.getDraftRecordById?.mostRecent,
                  competitorPrioritization:
                    this.getDraftRecordById?.competitorPrioritization,
                  competitorsPrioritizationTable:
                    this.getDraftRecordById?.competitorsPrioritizationTable,
                  stage: this.getDraftRecordById?.stage,
                  status: this.getDraftRecordById?.status,
                  updatedDate: this.getDraftRecordById?.updatedDate,
                  valid_from_date: this.getDraftRecordById?.valid_from_date,
                  validFromDateValue: this.validFromDateValue,
                });

                //----Set various InputType files-----
                this.setInputFile(this.getDraftRecordById?.ruleSheet, 1);
                this.setInputFile(
                  this.getDraftRecordById?.competitorsPrioritizationTable,
                  2
                );
                this.setInputFile(this.getDraftRecordById?.caiTable, 5);
              },
              (err) => {
                console.log(
                  `Fetching countries is failed for regionId: ${this.storeRegionId}` +
                    err
                );
                this.spinnerService.hide();
              }
            );
          }
        },
        (err: any) => {
          this.spinnerService.hide();
        }
      );
    } else {
      this.step = 1;
      this.maxStep = this.step;
      this.spinnerService.hide();
    }
  }

  // regions call
  getRegionDetails() {
    this.pairingStepFormApi
      .getRegions(
        this.userId,
        this.selectedBusinessLine,
        this.selectedProductLine
      )
      .subscribe(
        (res) => {
          const regionLsts = res;
          this.regions = regionLsts.result;
          this.regions = this.regions.filter(function (el: any) {
            return el !== 'NULL';
          });
          this.showBoolean = false;
        },
        (err) => {
          this.spinnerService.hide();
          this.showBoolean = true;
        }
      );
  }

  clearBrandDetails() {
    this.michelinBrands = [];
    this.otherBrands = [];
    this.seasons = [];
    this.otherSeasons = [];
    this.tyreLines = [];
    this.otherTirelines = [];
    this.tyreUsages = [];
    this.otherTyreUsages = [];
    this.selectedMichelinBrand = [];
    this.selectedOtherBrand = [];
    this.selectedSeason = [];
    this.selectedOtherSeason = [];
    this.selectedTyreLine = [];
    this.selectedOtherTireline = [];
    this.selectedTyreUsage = [];
    this.selectedOtherTyreUsage = [];
    this.multiStepForm.patchValue({
      brandX: null,
      brandY: null,
      seasonX: null,
      seasonY: null,
      usageX: null,
      usageY: null,
      tirelineX: null,
      tirelineY: null,
    });
    this.onDeSelectAllMichelinBrands();
    this.onDeSelectAllOtherBrands();
    this.onDeSelectAllSeasons();
    this.onDeSelectAllTyreLines();
    this.onDeSelectAllOtherSeasons();
    this.onDeSelectAllOtherTirelines();
  }

  // michelin brand call
  fetchMichelinBrands() {
    this.pairingStepFormApi.getMichelinBrands(this.getPLCode()).subscribe(
      (res) => {
        const michelinBrandLists = res;
        this.michelinBrands = michelinBrandLists.result;
        this.michelinBrands = this.michelinBrands.filter(function (el: any) {
          return el !== 'NULL';
        });

        this.selectedMichelinBrand = PairingHelper.getSelectedRecords(
          this.michelinBrands,
          this.getDraftRecordById?.brandX ?? [],
          'brandName'
        );
        this.getBrandIds = this.selectedMichelinBrand.map((item: any) => {
          return item.brandId;
        });

        if (this.selectedMichelinBrand.length > 0)
          this.relativeActionOnMichelinBrand();
      },
      (err) => {
        this.spinnerService.hide();
      }
    );
  }

  // Other brand call
  getOtherBrands(
    isMichelinExcluded: boolean,
    isMichelinExclusionClicked: boolean
  ) {
    this.pairingStepFormApi
      .getOtherBrands(isMichelinExcluded, this.getPLCode())
      .subscribe(
        (res) => {
          const otherBrandLists = res;
          this.otherBrands = otherBrandLists.result;
          this.otherBrands = this.otherBrands.filter(function (el: any) {
            return el !== 'NULL';
          });

          if (!(this.isClearAllOnPerimeter || isMichelinExclusionClicked)) {
            this.selectedOtherBrand = PairingHelper.getSelectedRecords(
              this.otherBrands,
              this.getDraftRecordById?.brandY ?? [],
              'brandName'
            );
          }

          if (this.selectedOtherBrand.length > 0)
            this.relativeActionOnOtherBrand();
        },
        (err) => {
          this.spinnerService.hide();
        }
      );
  }

  // product Line call
  getProductLineDetails(regionCodeOnEdit: any) {
    this.pairingStepFormApi
      .getProductLines(
        regionCodeOnEdit,
        this.userId.toString(),
        this.selectedBusinessLine
      )
      .subscribe(
        (res) => {
          const productLineLists = res;
          this.productLines = productLineLists.result;
          this.productLines = this.productLines.filter(function (el: any) {
            return el !== 'NULL';
          });
          if (this.editMode) {
            this.isProductLineDisabledOnRegion = false;
            this.selectedProductLine = PairingHelper.getSelectedRecords(
              this.productLines,
              this.getDraftRecordById?.productLine,
              'code'
            );
          } else {
            this.isProductLineDisabledOnRegion = true;
          }
          return this.productLines;
        },
        (err) => {
          this.spinnerService.hide();
        }
      );
  }

  // Business Line call
  getBusinessLineDatails(regionCodeOnEdit: any, productLineCodeOnEdit: any) {
    this.pairingStepFormApi
      .getBusinessLines(regionCodeOnEdit, productLineCodeOnEdit, this.userId)
      .subscribe(
        (res) => {
          const businessLineLists = res;
          this.businessLines = businessLineLists.result;
          this.businessLines = this.businessLines.filter(function (el: any) {
            return el !== 'NULL';
          });
        },
        (err) => {
          this.spinnerService.hide();
        }
      );
  }

  setInputFile(inputFileName: any, fileTypeId: number) {
    if (inputFileName != undefined && inputFileName != null) {
      this.inputFilesFormControls.push(
        this.fb.group({
          fileName: inputFileName,
          fileTypeId: fileTypeId,
        })
      );
    }
  }

  goToHome(event: any) {
    if (event === 'goToHome') {
      this.landHome = true;
    }
    this.currentActionType = this.SAVEDRAFT;
    this.popupMessage = PairingConst.SAVE_DRAFT_MSG;
    if (!this.checkFormDirtyState()) this.router.navigate(['/pairingAsProduct']);
  }

  clearAction() {
    if (this.step == 2) {
      this.stepLabelText = 'Clustering';
    } else if (this.step == 3) {
      this.stepLabelText = 'Degradation';
    }
    this.currentActionType = this.CLEARALL;
    this.popupMessage = PairingConst.CLEARALL_MSG;
    this.checkFormDirtyState();
  }

  checkFormDirtyState() {
    if (this.isMultiStepFormDirty) {
      this.isConfirmationPopupVisibale = true;
      this.displayStyle = 'block';
      return true;
    }
    this.onClear();
    return false;
  }

  onCancel() {
    this.displayStyle = 'none';
    this.isConfirmationPopupVisibale = false;
    if (this.landHome) {
      this.router.navigate(['/pairingAsProduct']);
    }
  }

  onCancelThroughIcon() {
    this.displayStyle = 'none';
    this.isConfirmationPopupVisibale = false;
    this.landHome = false;
  }

  degradationAction() {
    (<FormArray>this.multiStepForm.get('degradation')).clear();
    this.getDraftRecordById?.degradation.forEach((degradation: any) => {
      (<FormArray>this.multiStepForm.get('degradation')).push(
        new FormControl(degradation)
      );
    });

    this.degradationData = this.multiStepForm.get('degradation').value;
    this.stepLabelText = 'Degradation';
    if (this.degradationData.length > 0) {
      this.isDownloadBoxDisabled = true;
    }

    if (this.degradationData.includes('load_index')) {
      this.Loadintersection.push(
        this.getPLCode() === 'AG' ? 'Ply Rating' : 'Load Range'
      );
    } else if (this.degradationData.includes('load_range')) {
      this.Loadintersection.push(
        this.getPLCode() === 'PL' ? 'Load Index Single' : 'Load Index'
      );
    } else if (this.degradationData.includes('tyre_ply_rating')) {
      this.Loadintersection.push('Load Index');
    } else {
      this.Loadintersection = [];
    }

    this.relativeActionOnDegradationPopulation();
  }

  clusteringAction() {
    (<FormArray>this.multiStepForm.get('clustering')).clear();
    this.getDraftRecordById?.clustering.forEach((cluster: any) => {
      (<FormArray>this.multiStepForm.get('clustering')).push(
        new FormControl(cluster)
      );
    });
    this.stepLabelText = 'Clustering';
    this.clusteringData = this.multiStepForm.get('clustering').value;
    if(this.getPLCode() === 'TC') {
      if (this.clusteringData.includes('usage')) {
        //this.intersection.push('macro_usage');
        this.isMacroUsageSelected = false;
      } else if(this.clusteringData.includes('macro_usage')) {
        //this.intersection.push('usage');
        this.isMacroUsageSelected = true;
      }
    }
    this.relativeActionOnClusteringPopulation();
  }

  fillMultiStepForm(attrs: string[]): FormGroup {
    for (let attr of attrs) {
      if (this.getDraftRecordById?.hasOwnProperty(attr)) {
        for (let atrObj of this.getDraftRecordById[attr]) {
          (<FormArray>this.multiStepForm.get(attr)).push(
            new FormControl(atrObj)
          );
        }
      }
    }

    return this.multiStepForm;
  }
  
  logRange(event: any) {
    const getString = event.split(':')[1].replace('to', '-');

    const Month = PairingConst.Month;
    const indexMonth = PairingConst.IndexMonth;
    
    const monthNumber1 =
      indexMonth[
        Month.indexOf(
          event.split(':')[1].replace('to', '-').split('-')[0].split(' ')[1]
        )
      ];

    const monthNumber2 =
      indexMonth[
        Month.indexOf(
          event.split(':')[1].replace('to', '-').split('-')[1].split(' ')[1]
        )
      ];

    const month1 = new Date();
    month1.setDate(1);
    const month2 = new Date();
    month2.setDate(1);
    month1.setMonth(monthNumber1 - 1);
    month2.setMonth(monthNumber2 - 1);

    let m1;
    let m2;

    if (
      event.split(':')[1].replace('to', '-').split('-')[0].split(' ')[1] ==
      event.split(':')[1].replace('to', '-').split('-')[1].split(' ')[1]
    ) {
      m2 = getString.replaceAll(
        event.split(':')[1].replace('to', '-').split('-')[0].split(' ')[1],
        month1.toLocaleString('en-US', { month: 'long' })
      );
    } else {
      m1 = getString.replace(
        event.split(':')[1].replace('to', '-').split('-')[0].split(' ')[1],
        month1.toLocaleString('en-US', { month: 'long' })
      );
      m2 = m1.replace(
        event.split(':')[1].replace('to', '-').split('-')[1].split(' ')[1],
        month2.toLocaleString('en-US', { month: 'long' })
      );
    }
    
    this.priceDate = m2.trimStart();

    const m3 = month1.toLocaleString('en-US', { month: '2-digit' });
    const m4 = month2.toLocaleString('en-US', { month: '2-digit' });
    const year1 = this.priceDate.split(' ')[1];
    const year2 = this.priceDate.split(' ')[4];
    this.startDate = year1 + '-' + m3;
    this.endDate = year2 + '-' + m4;
    this.multiStepForm.patchValue({
      pricePeriodStart: this.startDate,
      pricePeriodEnd: this.endDate,
    });
  }

    logRange2(event: any) {
      const getString = event.split(':')[1].replace('to', '-');
  
      const Month = PairingConst.Month;
      const indexMonth = PairingConst.IndexMonth;
      console.log( indexMonth[
        Month.indexOf(
          event.split(':')[1].replace('to', '-').split('-')[0].split(' ')[1]
        )
      ]);
      const monthNumber3 =indexMonth[
        Month.indexOf(
          event.split(':')[1].replace('to', '-').split('-')[0].split(' ')[1]
        )
      ];
      const monthNumber1 =
        indexMonth[
          Month.indexOf(
            event.split(':')[1].replace('to', '-').split('-')[0].split(' ')[1]
          )
        ];
  
      const month1 = new Date();
      month1.setDate(1);
      month1.setMonth(monthNumber1 - 1);
  
      let m1;
      let m2;
        m1 = getString.replace(
          event.split(':')[1].replace('to', '-').split('-')[0].split(' ')[1],
          month1.toLocaleString('en-US', { month: 'long' })
        );
        console.log(m1);
        
      this.valid_from_date = m1.trimStart();
  
      const m3 = month1.toLocaleString('en-US', { month: '2-digit' });
      const year1 = this.valid_from_date.split(' ')[1];
      this.validStartDate = year1 + '-' + m3;
      this.multiStepForm.patchValue({
        valid_from_date: this.validStartDate,
        validFromDateValue: this.valid_from_date,  
      });
    }

  
   
    onMonthPickerOpen(type: string) {
      if (!this.isPriceMonthDisable && type === 'monthRange' ) {
        this.validFromDateFlag = false;
        this.priceDateFlag = true;
        this.isPriceDateValidation = true;
        this.isMonthPickerVisible = !this.isMonthPickerVisible;
        this.utilService.isCalendarPopUpOpen.subscribe(
          (res) => {
            const checkResValue = res;
            if (checkResValue) {
              this.isMonthPickerVisible = true;
            } else {
              this.isMonthPickerVisible = false;
            }
          },
          (err: any) => {
            this.spinnerService.hide();
            console.log(err);
          }
        );
      }
      else if (!this.isValidFromDateDisable && type === 'valid_from_date' ) {
        this.validFromDateFlag = true;
        this.priceDateFlag = false;
        this.isValidFromDateValidation = true;
        this.isValidFromDateVisible = !this.isValidFromDateVisible;
        this.utilService.isCalendarPopUpOpen.subscribe(
          (res) => {
            const checkResValue = res;
            if (checkResValue) {
              this.isValidFromDateVisible = true;
            } else {
              this.isValidFromDateVisible = false;
            }
          },
          (err: any) => {
            this.spinnerService.hide();
            console.log(err);
          }
        );
      }
    }


  onRegionId(regionId: number, regionCode: string) {
    this.spinnerService.show();
    this.isMultiStepFormDirty = true;
    this.storeRegionId = regionId;
    this.storeRegionCode = regionCode;
    this.isBusinessLineDisabledOnProductLine = true;
    this.isProductLineDisabledOnRegion = false;
    this.isRegionSelected = true;

    this.multiStepForm.patchValue({
      regionCode: this.storeRegionCode,
    });
    if (this.storeRegionCode === 'WW') {
      this.applyRegionWW();
    }

    this.checkCountryWW(this.storeRegionCode);

    if (this.selectedCountries.length >= 1) {
      this.selectedCountries = [];
      this.countries = [];
      this.onDeSelectAllCountys();
    }

    if (this.selectedProductLine.length >= 1) {
      this.selectedProductLine = [];
      this.productLines = [];
      this.isMultiStepFormDirty = true;
      (<FormArray>this.multiStepForm.get('productLine')).clear();
    }

    if (this.selectedBusinessLine.length >= 1) {
      this.selectedBusinessLine = [];
      this.businessLines = [];
      this.isMultiStepFormDirty = true;
      (<FormArray>this.multiStepForm.get('businessLine')).clear();
    }

    // product Line call
    this.pairingStepFormApi
      .getProductLines(
        this.storeRegionCode,
        this.userId,
        this.selectedBusinessLine
      )
      .subscribe(
        (res) => {
          const productLineLists = res;
          this.productLines = productLineLists.result;
          this.productLines = this.productLines.filter(function (el: any) {
            return el !== 'NULL';
          });
        },
        (err) => {
          this.spinnerService.hide();
        }
      );

    this.pairingStepFormApi.getCountrys(regionId).subscribe(
      (res) => {
        this.spinnerService.hide();
        const countryLists = res;
        this.countries = countryLists.result;
        this.countries = this.countries.filter(function (el: any) {
          return el !== 'NULL';
        });
      },
      (err) => {
        this.spinnerService.hide();
      }
    );

    if (this.storeRegionCode && this.getFileName !== null) {
      this.onClearDegradation();
    }
  }

  // get clustering values
  onParameter() {
    let plCode = this.getPLCode();
    this.pairingStepFormApi
      .getClusteringValues(this.storeRegionId, this.stepLabelText, plCode)
      .subscribe(
        (res) => {
          this.spinnerService.hide();
          const clusteringLists = res;
          this.clusterings = clusteringLists.result;
          this.clusterings = this.clusterings.filter(function (el: any) {
            return el !== 'NULL';
          });
        },
        (err) => {
          this.spinnerService.hide();
        }
      );
  }

  onChecked(e: any) {
    this.isMultiStepFormDirty = true;
    this.isCompetitorChecked = e.target.checked;

    if (!this.isCompetitorChecked) {
      this.resetCompetitorFields();
      this.removeFilesByType(2);
    }
  }

  private resetCompetitorFields() {
    this.multiStepForm.patchValue({
      competitorsPrioritizationTable: null,
    });
    this.successMessageComp = false;
    this.getFileNameCompetitor = null;
    this.getFileSizeCompetitor = null;
  }

  onCheckedCAI(e: any) {
    this.isMultiStepFormDirty = true;
    this.isCAIChecked = e.target.checked;

    if (this.isCAIChecked) {
      this.disableControls();
      this.clearFormArrays(['brandX', 'seasonX', 'usageX', 'tirelineX']);
    } else {
      this.enableControls();
      this.clearCAIFileData();
      this.removeFilesByType(5);
    }
  }

  private disableControls() {
    this.isButtonDisabled = true;
    this.isMichelinBrandDisabled = true;
    this.isSeasonDisabled = true;
    this.isTireUsageDisabled = true;
    this.isTireLineDisabled = true;
  }

  private enableControls() {
    this.isCAIChecked = false;
    this.isMichelinBrandDisabled = false;
    this.isButtonDisabled = false;
    this.successMessageCAI = false;
    this.isCAIFileMissing = false;
    this.errorFlagCAI = false;
  }

  private clearFormArrays(fields: string[]) {
    fields.forEach((field) => {
      (<FormArray>this.multiStepForm.get(field)).clear();
      this.multiStepForm.patchValue({ [field]: null });

      switch (field) {
        case 'brandX':
          this.selectedMichelinBrand = [];
          break;
        case 'seasonX':
          this.selectedSeason = [];
          this.seasons = [];
          break;
        case 'usageX':
          this.selectedTyreUsage = [];
          this.tyreUsages = [];
          break;
        case 'tirelineX':
          this.selectedTyreLine = [];
          this.tyreLines = [];
          break;
      }
    });
  }

  private clearCAIFileData() {
    this.getCAIFileName = null;
    this.getCAIFileSize = null;
    this.caiError = null;
  }

  private removeFilesByType(fileTypeId: number) {
    const inputFiles = this.multiStepForm.get('inputFiles').value;

    inputFiles.forEach((file: any, index: number) => {
      if (file.fileTypeId === fileTypeId) {
        this.inputFilesFormControls.removeAt(index);
      }
    });
  }

  buildForm() {
    this.multiStepForm = this.fb.group({
      id: new FormControl(),
      userId: new FormControl(this.userId.toString()),
      region: new FormControl(),
      regionCode: new FormControl(),
      country: new FormArray([]),
      productLine: new FormArray([]),
      businessLine: new FormArray([]),
      brandX: new FormArray([]),
      seasonX: new FormArray([]),
      usageX: new FormArray([]),
      tirelineX: new FormArray([]),
      isCAI: new FormControl(false),
      caiTable: new FormControl(),
      brandY: new FormArray([]),
      seasonY: new FormArray([]),
      usageY: new FormArray([]),
      tirelineY: new FormArray([]),
      isMichelinExcluded: new FormControl(false),

      clustering: new FormArray([]),
      degradation: new FormArray([], Validators.required),
      ruleSheet: new FormControl(),
      priceDate: new FormControl(null, Validators.required),
      pricePeriodStart: new FormControl(null),
      pricePeriodEnd: new FormControl(null),
      mostPopular: new FormControl('False'),
      mostRecent: new FormControl('False'),
      priceType: new FormArray([]),
      orderPreference: new FormArray([]),
      competitorPrioritization: new FormControl(false),
      competitorsPrioritizationTable: new FormControl(),
      stage: new FormControl(),
      status: new FormControl(),
      inputFiles: new FormArray([]),
      updatedDate: new FormControl(),
      valid_from_date: new FormControl(null),
      validFromDateValue: new FormControl(null, Validators.required),
    });
  }

  get f() {
    return this.multiStepForm.controls['productLine'] as FormArray;
  }

  get inputFilesFormControls(): FormArray {
    return <FormArray>this.multiStepForm.get('inputFiles');
  }

  get priceType(): FormArray {
    return this.multiStepForm.get('priceType') as FormArray;
  }

  get orderPreference(): FormArray {
    return this.multiStepForm.get('orderPreference') as FormArray;
  }

  get productLine(): FormArray {
    return this.multiStepForm.get('productLine') as FormArray;
  }

  get businessLine(): FormArray {
    return this.multiStepForm.get('businessLine') as FormArray;
  }

  get clusteringValuesControl() {
    return this.multiStepForm.get('clustering') as FormArray;
  }

  get degradationValuesControl() {
    return this.multiStepForm.get('degradation') as FormArray;
  }

  onTyreUsageSelect(item: any) {
    this.isMultiStepFormDirty = true;
    const control = new FormControl(item.usageName);
    (<FormArray>this.multiStepForm.get('usageX')).push(control);

    this.multiStepForm.patchValue({
      usageX: this.selectedTyreUsage.usageName,
    });

    this.relativeActionOnTyreUsage();
  }

  onTyreUsageDeselect(item: any) {
    this.isTireLineDisabled = false;
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('tirelineX')).clear();
    this.selectedTyreLine = [];
    this.multiStepForm.patchValue({
      tirelineX: null,
    });

    if (item.length > 0) {
      (<FormArray>this.multiStepForm.get('tirelineX')).clear();
      this.isTireLineDisabled = false;
    }

    let i = 0;
    (<FormArray>this.multiStepForm.get('usageX')).controls.forEach((l: any) => {
      if (l.value == item.usageName) {
        (<FormArray>this.multiStepForm.get('usageX')).removeAt(i);
        return;
      }
      i++;
    });

    // received usages
    let usagesData = this.selectedTyreUsage;
    this.getUsages = usagesData.map((item: any) => {
      return item.usageId;
    });

    // received brands
    let data = this.selectedMichelinBrand;
    this.getBrandIds = data.map((item: any) => {
      return item.brandId;
    });

    let data2 = this.selectedSeason;

    this.alllist = [];

    for (let j of data2) {
      let data3 = this.masterdata.subSeasonMap[j];
      for (const element of data3) {
        this.alllist.push(element);
      }
    }

    if (this.alllist.length !== 0) {
      for (const element of this.alllist) {
        this.checkElementNotNull(element);
        this.getAllsubSeasonIdsIn = this.alllist.map((item: any) => {
          return item.subSeasonId;
        });
      }
    } else {
      this.getAllsubSeasonIdsIn = [];
    }

    this.spinnerService.show();

    if (this.getPLCode() != 'AG' && this.getPLCode() != '2W' && this.getPLCode() != 'CT') {
      this.getMichelinTyreLines(
        this.getBrandIds,
        this.getAllsubSeasonIdsIn,
        this.getUsages
      );
    } else if (this.getPLCode() == '2W') {
      this.getMichelinTyreLines(this.getBrandIds, [], this.getUsages);
    }

    if (this.tyreLines.length == 0) {
      this.isTireLineDisabled = true;
    }
  }

  onSelectAllTyreUsages(items: any) {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('usageX')).clear();
    (<FormArray>this.multiStepForm.get('tirelineX')).clear();
    for (let key of items) {
      const controls = new FormControl(key.usageName);
      (<FormArray>this.multiStepForm.get('usageX')).push(controls);
    }

    this.isTireLineDisabled = false;

    let usageStore: any = [];
    for (let key of items) {
      const controls = new FormControl(key.usageId);
      usageStore.push(controls.value);
    }
    this.selectedTyreUsage = usageStore;

    let data = this.selectedMichelinBrand;
    this.getBrandIds = data.map((item: any) => {
      return item.brandId;
    });

    this.alllist = [];

    for (let j of this.seasonitems) {
      let data3 = this.masterdata.subSeasonMap[j];
      for (const element of data3) {
        this.alllist.push(element);
      }
    }

    for (const element of this.alllist) {
      this.checkElementNotNull(element);
      this.getAllsubSeasonIdsIn = this.alllist.map((item: any) => {
        return item.subSeasonId;
      });
    }

    this.spinnerService.show();

    if (this.getPLCode() != 'AG' && this.getPLCode() != '2W' && this.getPLCode() != 'CT') {
      this.getMichelinTyreLines(
        this.getBrandIds,
        this.getAllsubSeasonIdsIn,
        this.selectedTyreUsage
      );
    } else if (this.getPLCode() == '2W') {
      this.getMichelinTyreLines(this.getBrandIds, [], this.selectedTyreUsage);
    }
  }

  onDeSelectAllTyreUsages(items: any) {
    this.isMultiStepFormDirty = true;
    this.isTireLineDisabled = true;
    (<FormArray>this.multiStepForm.get('usageX')).clear();
    (<FormArray>this.multiStepForm.get('tirelineX')).clear();
    this.tyreLines = [];
    this.selectedTyreLine = null;
    this.multiStepForm.patchValue({
      tirelineX: this.selectedTyreLine.categoryName,
    });

    let arr: any = [];

    for (let key of items) {
      const controls = new FormControl(key.usageId);
      arr.push(JSON.stringify(controls.value));
    }
    this.selectedTyreUsage = arr;

    this.spinnerService.show();

    if (this.getPLCode() != 'AG' && this.getPLCode() != '2W' && this.getPLCode() != 'CT') {
      this.getMichelinTyreLines(
        this.getAllBrandIds,
        this.getAllselectedSeason,
        this.selectedTyreUsage
      );
    } else if (this.getPLCode() == '2W') {
      this.getMichelinTyreLines(
        this.getAllBrandIds,
        [],
        this.selectedTyreUsage
      );
    }
  }

  onTyreLineSelect(item: any) {
    this.isMultiStepFormDirty = true;
    const control = new FormControl(item.categoryName);
    (<FormArray>this.multiStepForm.get('tirelineX')).push(control);

    this.multiStepForm.patchValue({
      tirelineX: this.selectedTyreLine.categoryName,
    });
  }

  onTyreLineDeselect(item: any) {
    this.isMultiStepFormDirty = true;
    let i = 0;
    (<FormArray>this.multiStepForm.get('tirelineX')).controls.forEach(
      (l: any) => {
        if (l.value == item.categoryName) {
          (<FormArray>this.multiStepForm.get('tirelineX')).removeAt(i);
          return;
        }
        i++;
      }
    );
  }

  onSelectAllTyreLines(items: any) {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('tirelineX')).clear();
    for (let key of items) {
      const controls = new FormControl(key.categoryName);
      (<FormArray>this.multiStepForm.get('tirelineX')).push(controls);
    }
  }

  onDeSelectAllTyreLines() {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('tirelineX')).clear();
    this.selectedTyreLine = [];
  }

  onSeasonSelect(item: any) {
    this.isMultiStepFormDirty = true;
    const control = new FormControl(item);
    (<FormArray>this.multiStepForm.get('seasonX')).push(control);

    //** */
    (<FormArray>this.multiStepForm.get('usageX')).clear();
    this.selectedTyreUsage = [];
    this.multiStepForm.patchValue({
      usageX: null,
    });
    this.tyreUsages = [];

    (<FormArray>this.multiStepForm.get('tirelineX')).clear();
    this.selectedTyreLine = [];
    this.multiStepForm.patchValue({
      tirelineX: null,
    });
    this.tyreLines = [];
    //** */

    this.relativeActionOnSeason();
  }

  relativeActionOnSeason() {
    if (
      this.multiStepForm.get('seasonX').value.length > 0 && 
      this.getPLCode() != 'AG' && this.getPLCode() != '2W' 
      && this.getPLCode() != 'CT') {
      this.isTireUsageDisabled = false;
    }

    let data = this.selectedMichelinBrand;
    this.getBrandIds = data.map((item: any) => {
      return item.brandId;
    });

    this.alllist = [];

    let data2 = this.selectedSeason;
    for (let j of data2) {
      let data3 = this.masterdata.subSeasonMap[j];
      for (const element of data3) {
        this.alllist.push(element);
      }
    }

    for (const element of this.alllist) {
      this.checkElementNotNull(element);
      this.getAllsubSeasonIdsIn = this.alllist.map((item: any) => {
        return item.subSeasonId;
      });
    }

    this.spinnerService.show();

    if (this.getPLCode() != '2W' && this.getPLCode() != 'AG' && this.getPLCode() != 'CT') {
      // get tyre usage
      this.getTyreUsage(
        this.getBrandIds,
        this.getAllsubSeasonIdsIn,
        this.getPLCode()
      );
    }

    this.alllist = [];
  }

  relativeActionOnTyreUsage() {
    if (
      this.multiStepForm.get('usageX').value.length > 0 &&
      this.getPLCode() != 'AG' && this.getPLCode() != 'CT') {
      this.isTireLineDisabled = false;
    }

    let data = this.selectedMichelinBrand;
    this.getBrandIds = data.map((item: any) => {
      return item.brandId;
    });

    let data2 = this.selectedSeason;

    this.alllist = [];

    for (let j of data2) {
      let data3 = this.masterdata.subSeasonMap[j];
      for (const element of data3) {
        this.alllist.push(element);
      }
    }

    for (const element of this.alllist) {
      this.checkElementNotNull(element);
      this.getAllsubSeasonIdsIn = this.alllist.map((item: any) => {
        return item.subSeasonId;
      });
    }

    let data4 = this.selectedTyreUsage;
    this.getAllusageIdsIn = data4.map((item: any) => {
      return item.usageId;
    });

    this.spinnerService.show();

    if (this.getPLCode() != 'AG' && this.getPLCode() != '2W' && this.getPLCode() != 'CT') {
      this.getMichelinTyreLines(
        this.getBrandIds,
        this.getAllsubSeasonIdsIn,
        this.getAllusageIdsIn
      );
    } else if (this.getPLCode() == '2W') {
      this.getMichelinTyreLines(this.getBrandIds, [], this.getAllusageIdsIn);
    }
    if (this.editMode && this.tyreLines != undefined) {
      this.selectedTyreLine = PairingHelper.getSelectedRecords(
        this.tyreLines,
        this.getDraftRecordById?.tirelineX ?? [],
        'categoryName'
      );
    }
    this.alllist = [];
  }

  onSeasonDeselect(item: any) {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('usageX')).clear();
    this.selectedTyreUsage = [];
    this.multiStepForm.patchValue({
      usageX: null,
    });
    this.tyreUsages = [];

    (<FormArray>this.multiStepForm.get('tirelineX')).clear();
    this.selectedTyreLine = [];
    this.multiStepForm.patchValue({
      tirelineX: null,
    });
    this.tyreLines = [];

    if (item.length > 0 && this.getPLCode() != 'AG' && this.getPLCode() != 'CT') {
      (<FormArray>this.multiStepForm.get('usageX')).clear();
      this.isTireUsageDisabled = false;
    }

    let i = 0;
    (<FormArray>this.multiStepForm.get('seasonX')).controls.forEach(
      (l: any) => {
        if (l.value == item) {
          (<FormArray>this.multiStepForm.get('seasonX')).removeAt(i);
          return;
        }
        i++;
      }
    );

    let data = this.selectedMichelinBrand;
    this.getBrandIds = data.map((item: any) => {
      return item.brandId;
    });

    let data2 = this.selectedSeason;

    this.alllist = [];

    for (let j of data2) {
      let data3 = this.masterdata.subSeasonMap[j];
      for (const element of data3) {
        this.alllist.push(element);
      }
    }

    if (this.alllist.length !== 0) {
      for (const element of this.alllist) {
        this.checkElementNotNull(element);
        this.getAllsubSeasonIdsIn = this.alllist.map((item: any) => {
          return item.subSeasonId;
        });
      }
    } else {
      this.getAllsubSeasonIdsIn = [];
    }
    this.spinnerService.show();

    if (this.getPLCode() != '2W' && this.getPLCode() != 'AG' && this.getPLCode() != 'CT') {
      // get tyre usage
      this.getTyreUsage(
        this.getBrandIds,
        this.getAllsubSeasonIdsIn,
        this.getPLCode()
      );
    }
  }

  onSelectAllSeasons(items: any) {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('seasonX')).clear();
    (<FormArray>this.multiStepForm.get('usageX')).clear();

    for (const element of items) {
      const control = new FormControl(element);

      (<FormArray>this.multiStepForm.get('seasonX')).push(control);
    }

    //** */
    (<FormArray>this.multiStepForm.get('usageX')).clear();
    this.selectedTyreUsage = [];
    this.multiStepForm.patchValue({
      usageX: null,
    });
    this.tyreUsages = [];

    (<FormArray>this.multiStepForm.get('tirelineX')).clear();
    this.selectedTyreLine = [];
    this.multiStepForm.patchValue({
      tirelineX: null,
    });
    this.tyreLines = [];
    //** */

    if (this.getPLCode() != 'AG' && this.getPLCode() != 'CT') {
      this.isTireUsageDisabled = false;
    }

    let data = this.selectedMichelinBrand;

    this.getBrandIds = data.map((item: any) => {
      return item.brandId;
    });

    this.seasonitems = items;

    this.alllist = [];

    for (let j of items) {
      let data3 = this.masterdata.subSeasonMap[j];
      for (const element of data3) {
        this.alllist.push(element);
      }
    }

    for (const element of this.alllist) {
      this.checkElementNotNull(element);
      this.getAllsubSeasonIdsIn = this.alllist.map((item: any) => {
        return item.subSeasonId;
      });
    }

    this.spinnerService.show();

    if (this.getPLCode() != '2W' && this.getPLCode() != 'AG' && this.getPLCode() != 'CT') {
      // get tyre usage
      this.getTyreUsage(
        this.getBrandIds,
        this.getAllsubSeasonIdsIn,
        this.getPLCode()
      );
    }
  }

  onDeSelectAllSeasons() {
    this.isMultiStepFormDirty = true;
    if (this.getPLCode() != 'AG' && this.getPLCode() != 'CT') {
      this.isTireUsageDisabled = false;
      this.isTireLineDisabled = false;
    }
    (<FormArray>this.multiStepForm.get('seasonX')).clear();
    this.selectedSeason = [];

    (<FormArray>this.multiStepForm.get('usageX')).clear();
    this.tyreUsages = [];
    this.selectedTyreUsage = [];
    this.multiStepForm.patchValue({
      usageX: null,
    });
    this.isTireUsageDisabled = true;

    (<FormArray>this.multiStepForm.get('tirelineX')).clear();
    this.tyreLines = [];
    this.selectedTyreLine = [];
    this.multiStepForm.patchValue({
      tirelineX: null,
    });
    this.isTireLineDisabled = true;
  }

  onBusinessLineSelect(item: any) {
    this.isMultiStepFormDirty = true;
    this.isBusinessLineSelected = true;
    const control = new FormControl(item.businessLineName, Validators.required);
    (<FormArray>this.multiStepForm.get('businessLine')).push(control);
  }

  onBusinessLineDeselect(item: any) {
    this.isMultiStepFormDirty = true;
    let i = 0;
    (<FormArray>this.multiStepForm.get('businessLine')).controls.forEach(
      (l: any) => {
        if (l.value == item.businessLineName) {
          (<FormArray>this.multiStepForm.get('businessLine')).removeAt(i);
          this.isBusinessLineSelected = true;
          return;
        }
        i++;
      }
    );
  }

  onProductLineSelect(item: any) {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('productLine')).clear();
    this.isProductLineSelected = true;
    this.isBusinessLineDisabledOnProductLine = false;
    const control = new FormControl(item.code, Validators.required);
    (<FormArray>this.multiStepForm.get('productLine')).push(control);
    this.getParameterId(item.code);
    this.getBusinessLineDatails(this.storeRegionCode, item.code);
    this.clearBrandDetails();
    this.clearClustering();
    this.clearDegradation();
    this.errorFlagDeg = false;
    this.successMessageDeg = false;
    this.fetchMichelinBrands();
    this.getFileName = undefined;
    this.getFileSize = false;

    // other brands call
    if (!this.editMode) {
      this.getOtherBrands(this.isMichelinBrandsExcluded, false);
    }
  }

  onProductLineDeselect(item: any) {
    this.getPriceTypeParameterLabel(item);
    this.isMultiStepFormDirty = true;
    let i = 0;
    (<FormArray>this.multiStepForm.get('productLine')).controls.forEach(
      (l: any) => {
        if (l.value == item.code) {
          (<FormArray>this.multiStepForm.get('productLine')).removeAt(i);
          this.isProductLineSelected = true;
          return;
        }
        i++;
      }
    );
    this.isBusinessLineDisabledOnProductLine = true;
    (<FormArray>this.multiStepForm.get('businessLine')).clear();
    this.businessLines = [];
    this.selectedBusinessLine = [];
    this.multiStepForm.patchValue({
      businessLine: null,
    });
  }

  onMichelinBrandSelect(item: any) {
    this.spinnerService.show();
    this.isMultiStepFormDirty = true;
    const control = new FormControl(item.brandName);
    (<FormArray>this.multiStepForm.get('brandX')).push(control);

    // **
    (<FormArray>this.multiStepForm.get('seasonX')).clear();
    this.selectedSeason = [];
    this.multiStepForm.patchValue({
      seasonX: null,
    });
    this.seasons = [];

    (<FormArray>this.multiStepForm.get('usageX')).clear();
    this.selectedTyreUsage = [];
    this.multiStepForm.patchValue({
      usageX: null,
    });
    this.tyreUsages = [];

    (<FormArray>this.multiStepForm.get('tirelineX')).clear();
    this.selectedTyreLine = [];
    this.multiStepForm.patchValue({
      tirelineX: null,
    });
    this.tyreLines = [];
    //** */

    this.multiStepForm.patchValue({
      brandX: this.selectedMichelinBrand.brandName,
      /* usageX: null,
        tirelineX: null, */
    });

    this.relativeActionOnMichelinBrand();
  }

  relativeActionOnMichelinBrand() {
    let data = this.selectedMichelinBrand;
    this.getBrandIds = data.map((item: any) => {
      return item.brandId;
    });

    if (
      this.multiStepForm.get('brandX').value.length > 0 &&
      this.getPLCode() != 'AG' && this.getPLCode() != '2W'
      && this.getPLCode() != 'CT') {
      this.isSeasonDisabled = false;
      // // season call
      this.pairingStepFormApi
        .getSubSeasons(this.getBrandIds, this.getPLCode())
        .subscribe(
          (res) => {
            this.spinnerService.hide();
            const seasonLists = res;
            const data = seasonLists.result;
            this.masterdata = seasonLists.result;
            this.seasons = Object.keys(data.subSeasonMap);

            //------For SeasonX-----------
            if (this.editMode) {
              this.selectedSeason = PairingHelper.getSelectedRecordsFromArry(
                this.seasons,
                this.getDraftRecordById?.seasonX ?? []
              );
              if (this.selectedSeason.length > 0) this.relativeActionOnSeason();
            }
          },
          (err: any) => {
            this.spinnerService.hide();
          }
        );
    } else if (this.getPLCode() == 'AG' || this.getPLCode() == 'CT') {
      this.isTireLineDisabled = false;
      this.isSeasonDisabled = true;
      this.isTireUsageDisabled = true;
      this.getMichelinTyreLines(this.getBrandIds, [], []);
    } else if (this.getPLCode() == '2W') {
      this.isTireLineDisabled = true;
      this.isSeasonDisabled = true;
      this.isTireUsageDisabled = false;
      this.getTyreUsage(this.getBrandIds, [], this.getPLCode());
    }
  }

  onOtherBrandSelect(item: any) {
    this.spinnerService.show();
    this.isMultiStepFormDirty = true;
    const control = new FormControl(item.brandName);
    (<FormArray>this.multiStepForm.get('brandY')).push(control);

    // **
    (<FormArray>this.multiStepForm.get('seasonY')).clear();
    this.selectedOtherSeason = [];
    this.multiStepForm.patchValue({
      seasonY: null,
    });
    this.otherSeasons = [];

    (<FormArray>this.multiStepForm.get('usageY')).clear();
    this.selectedOtherTyreUsage = [];
    this.multiStepForm.patchValue({
      usageY: null,
    });
    this.otherTyreUsages = [];

    (<FormArray>this.multiStepForm.get('tirelineY')).clear();
    this.selectedOtherTireline = [];
    this.multiStepForm.patchValue({
      tirelineY: null,
    });
    this.otherTirelines = [];
    //** */

    this.multiStepForm.patchValue({
      brandY: this.selectedOtherBrand.brandName,
      /* usageX: null,
        tirelineX: null, */
    });

    this.relativeActionOnOtherBrand();
  }

  relativeActionOnOtherBrand() {
    let data = this.selectedOtherBrand;
    this.getOtherBrandIds = data.map((item: any) => {
      return item.brandId;
    });

    if (
      this.multiStepForm.get('brandY').value.length > 0 &&
      this.getPLCode() != 'AG' && this.getPLCode() != '2W'
      && this.getPLCode() != 'CT') {
      this.isOtherSeasonDisabled = false;

      // other season call
      this.pairingStepFormApi
        .getOtherSubSeasons(this.getOtherBrandIds, this.getPLCode())
        .subscribe(
          (res) => {
            this.spinnerService.hide();
            const otherSeasonLists = res;
            const data = otherSeasonLists.result;
            this.otherMasterData = otherSeasonLists.result;
            this.otherSeasons = Object.keys(data.subSeasonMap);

            //------For SeasonX-----------
            if (
              this.editMode &&
              this.otherSeasons != undefined &&
              !this.isClearAllOnPerimeter
            ) {
              this.selectedOtherSeason =
                PairingHelper.getSelectedRecordsFromArry(
                  this.otherSeasons,
                  this.getDraftRecordById?.seasonY ?? []
                );
              if (this.selectedOtherSeason.length > 0)
                this.relativeActionOnOtherSeason();
            }
          },
          (err: any) => {
            this.spinnerService.hide();
            console.log(err);
          }
        );
    } else if (this.multiStepForm.get('brandY').value.length > 0 &&
      this.getPLCode() == 'AG' || this.getPLCode() == 'CT') {
      this.isOtherTireLineDisabled = false;
      this.isOtherSeasonDisabled = true;
      this.isOtherTireUsageDisabled = true;
      this.getOtherTyreLines(this.getOtherBrandIds, [], []);
    } else if (
      this.multiStepForm.get('brandY').value.length > 0 &&
      this.getPLCode() == '2W'
    ) {
      this.isOtherTireLineDisabled = true;
      this.isOtherSeasonDisabled = true;
      this.isOtherTireUsageDisabled = false;
      this.getTyreOtherUsage(this.getOtherBrandIds, [], this.getPLCode());
    }
  }

  onMichelinBrandDeselect(item: any) {
    this.spinnerService.show();
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('seasonX')).clear();
    this.selectedSeason = [];
    this.multiStepForm.patchValue({
      seasonX: null,
    });
    this.seasons = [];

    (<FormArray>this.multiStepForm.get('usageX')).clear();
    this.selectedTyreUsage = [];
    this.multiStepForm.patchValue({
      usageX: null,
    });
    this.tyreUsages = [];

    (<FormArray>this.multiStepForm.get('tirelineX')).clear();
    this.selectedTyreLine = [];
    this.multiStepForm.patchValue({
      tirelineX: null,
    });
    this.tyreLines = [];

    let i = 0;
    (<FormArray>this.multiStepForm.get('brandX')).controls.forEach((l: any) => {
      if (l.value == item.brandName) {
        (<FormArray>this.multiStepForm.get('brandX')).removeAt(i);
        return;
      }
      i++;
    });

    let data = this.selectedMichelinBrand;
    this.getBrandIds = data.map((item: any) => {
      return item.brandId;
    });

    if (this.getBrandIds.length === 0) {
      this.isSeasonDisabled = true;
      this.isTireUsageDisabled = true;
      this.isTireLineDisabled = true;
    }
    this.relativeActionOnMichelinBrand();
  }

  onOtherBrandDeselect(item: any) {
    this.spinnerService.show();
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('seasonY')).clear();
    this.selectedOtherSeason = [];
    this.multiStepForm.patchValue({
      seasonY: null,
    });
    this.otherSeasons = [];

    (<FormArray>this.multiStepForm.get('usageY')).clear();
    this.selectedOtherTyreUsage = [];
    this.multiStepForm.patchValue({
      usageY: null,
    });
    this.otherTyreUsages = [];

    (<FormArray>this.multiStepForm.get('tirelineY')).clear();
    this.selectedOtherTireline = [];
    this.multiStepForm.patchValue({
      tirelineY: null,
    });
    this.otherTirelines = [];

    let i = 0;
    (<FormArray>this.multiStepForm.get('brandY')).controls.forEach((l: any) => {
      if (l.value == item.brandName) {
        (<FormArray>this.multiStepForm.get('brandY')).removeAt(i);
        return;
      }
      i++;
    });

    let data = this.selectedOtherBrand;
    this.getOtherBrandIds = data.map((item: any) => {
      return item.brandId;
    });

    if (this.getOtherBrandIds.length === 0) {
      this.isOtherSeasonDisabled = true;
      this.isOtherTireUsageDisabled = true;
      this.isOtherTireLineDisabled = true;
    }
    this.relativeActionOnOtherBrand();
  }

  onOtherTirelineSelect(item: any) {
    this.isMultiStepFormDirty = true;
    const control = new FormControl(item.categoryName);
    (<FormArray>this.multiStepForm.get('tirelineY')).push(control);
    this.multiStepForm.patchValue({
      tirelineY: this.selectedOtherTireline.categoryName,
    });
  }

  onOtherTirelineDeselect(item: any) {
    this.isMultiStepFormDirty = true;
    let i = 0;
    (<FormArray>this.multiStepForm.get('tirelineY')).controls.forEach(
      (l: any) => {
        if (l.value == item.categoryName) {
          (<FormArray>this.multiStepForm.get('tirelineY')).removeAt(i);
          return;
        }
        i++;
      }
    );
  }

  onSelectAllOtherTirelines(items: any) {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('tirelineY')).clear();
    for (let key of items) {
      const controls = new FormControl(key.categoryName);
      (<FormArray>this.multiStepForm.get('tirelineY')).push(controls);
    }
  }

  onDeSelectAllOtherTirelines() {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('tirelineY')).clear();
  }

  onCountrySelect(item: any) {
    this.isMultiStepFormDirty = true;
    const control = new FormControl(item.countryNamePfx);
    (<FormArray>this.multiStepForm.get('country')).push(control);
    this.multiStepForm.patchValue({
      country: this.selectedCountries.countryNamePfx,
    });
  }

  // Deselect dropdown values methods
  onCountryDeselect(item: any) {
    this.isMultiStepFormDirty = true;
    let i = 0;
    (<FormArray>this.multiStepForm.get('country')).controls.forEach(
      (l: any) => {
        if (l.value == item.countryNamePfx) {
          (<FormArray>this.multiStepForm.get('country')).removeAt(i);
          return;
        }
        i++;
      }
    );
  }

  onSelectAllCountrys(items: any) {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('country')).clear();
    for (let key of items) {
      const controls = new FormControl(key.countryNamePfx);
      (<FormArray>this.multiStepForm.get('country')).push(controls);
    }
  }

  onDeSelectAllCountys() {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('country')).clear();
  }

  onSelectAllMichelinBrands(items: any) {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('seasonX')).clear();
    this.selectedSeason = [];
    this.multiStepForm.patchValue({
      seasonX: null,
    });
    this.seasons = [];

    (<FormArray>this.multiStepForm.get('usageX')).clear();
    this.selectedTyreUsage = [];
    this.multiStepForm.patchValue({
      usageX: null,
    });
    this.tyreUsages = [];

    (<FormArray>this.multiStepForm.get('tirelineX')).clear();
    this.selectedTyreLine = [];
    this.multiStepForm.patchValue({
      tirelineX: null,
    });
    this.tyreLines = [];
   
    for (let key of items) {
      const controls = new FormControl(key.brandName);
      (<FormArray>this.multiStepForm.get('brandX')).push(controls);
    }

    let arr: any = [];

    for (let key of items) {
      const controls = new FormControl(key.brandId);
      arr.push(JSON.stringify(controls.value));
    }
    this.selectedMichelinBrand = arr;

    this.spinnerService.show();

    if (this.getPLCode() != 'AG' && this.getPLCode() != '2W' && this.getPLCode() != 'CT') {
      this.isSeasonDisabled = false;

      this.pairingStepFormApi
        .getSubSeasons(this.selectedMichelinBrand, this.getPLCode())
        .subscribe(
          (res) => {
            this.spinnerService.hide();
            const seasonLists = res;
            const data = seasonLists.result;
            this.masterdata = seasonLists.result;
            this.seasons = Object.keys(data.subSeasonMap);
          },
          (err: any) => {
            this.spinnerService.hide();
          }
        );
    } else if (this.getPLCode() == 'AG' || this.getPLCode() == 'CT') {
      this.isTireLineDisabled = false;
      this.isSeasonDisabled = true;
      this.isTireUsageDisabled = true;
      this.getMichelinTyreLines(this.selectedMichelinBrand, [], []);
    } else if (this.getPLCode() == '2W') {
      this.isTireLineDisabled = true;
      this.isSeasonDisabled = true;
      this.isTireUsageDisabled = false;
      this.getTyreUsage(this.selectedMichelinBrand, [], this.getPLCode());
    }

    this.getAllBrandIds = this.selectedMichelinBrand;
  }

  onDeSelectAllMichelinBrands() {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('brandX')).clear();

    (<FormArray>this.multiStepForm.get('seasonX')).clear();
    this.seasons = [];
    this.selectedSeason = [];
    this.multiStepForm.patchValue({
      seasonX: null,
    });
    this.isSeasonDisabled = true;

    (<FormArray>this.multiStepForm.get('usageX')).clear();
    this.tyreUsages = [];
    this.selectedTyreUsage = [];
    this.multiStepForm.patchValue({
      usageX: null,
    });
    this.isTireUsageDisabled = true;

    (<FormArray>this.multiStepForm.get('tirelineX')).clear();
    this.tyreLines = [];
    this.selectedTyreLine = [];
    this.multiStepForm.patchValue({
      tirelineX: null,
    });
    this.isTireLineDisabled = true;
  }

  onExcludingMichelinBrands(e: any) {
    this.isMultiStepFormDirty = true;
    if (e.target.checked) this.isMichelinBrandsExcluded = true;
    else this.isMichelinBrandsExcluded = false;

    this.getOtherBrands(this.isMichelinBrandsExcluded, true);

    this.relativeActionOnExcludeMichelinBrand();
  }

  relativeActionOnExcludeMichelinBrand() {
    (<FormArray>this.multiStepForm.get('brandY')).clear();
    (<FormArray>this.multiStepForm.get('seasonY')).clear();
    (<FormArray>this.multiStepForm.get('usageY')).clear();
    (<FormArray>this.multiStepForm.get('tirelineY')).clear();

    this.selectedOtherBrand = [];
    this.selectedOtherSeason = [];
    this.selectedOtherTyreUsage = [];
    this.selectedOtherTireline = [];

    this.isOtherSeasonDisabled = true;
    this.isOtherTireUsageDisabled = true;
    this.isOtherTireLineDisabled = true;

    this.multiStepForm.patchValue({
      brandY: null,
      tirelineY: null,
      seasonY: null,
      usageY: null,
      isMichelinExcluded: this.isMichelinBrandsExcluded,
    });
  }

  onSelectAllOtherBrands(items: any) {
    this.isMultiStepFormDirty = true;
    let arr: any = [];
   // **
   (<FormArray>this.multiStepForm.get('seasonY')).clear();
   this.selectedOtherSeason = [];
   this.multiStepForm.patchValue({
     seasonY: null,
   });
   this.otherSeasons = [];

   (<FormArray>this.multiStepForm.get('usageY')).clear();
   this.selectedOtherTyreUsage = [];
   this.multiStepForm.patchValue({
     usageY: null,
   });
   this.otherTyreUsages = [];

   (<FormArray>this.multiStepForm.get('tirelineY')).clear();
   this.selectedOtherTireline = [];
   this.multiStepForm.patchValue({
     tirelineY: null,
   });
   this.otherTirelines = [];
   
    for (let key of items) {
      const brandnames = new FormControl(key.brandName);
      const brandIds = new FormControl(key.brandId);
      arr.push(JSON.stringify(brandIds.value));
      (<FormArray>this.multiStepForm.get('brandY')).push(brandnames);
    }
    this.selectedOtherBrand = arr;

    if (this.getPLCode() != 'AG' && this.getPLCode() != '2W' && this.getPLCode() != 'CT') {
      this.isOtherSeasonDisabled = false;
      this.spinnerService.show();

      this.pairingStepFormApi
        .getOtherSubSeasons(this.selectedOtherBrand, this.getPLCode())
        .subscribe(
          (res) => {
            this.spinnerService.hide();
            const otherSeasonLists = res;
            const data = otherSeasonLists.result;
            this.otherMasterData = otherSeasonLists.result;
            this.otherSeasons = Object.keys(data.subSeasonMap);
          },
          (err: any) => {
            this.spinnerService.hide();
          }
        );
    } else if (this.getPLCode() == 'AG' || this.getPLCode() == 'CT') {
      this.isOtherTireLineDisabled = false;
      this.isOtherSeasonDisabled = true;
      this.isOtherTireUsageDisabled = true;
      this.getOtherTyreLines(this.selectedOtherBrand, [], []);
    } else if (this.getPLCode() == '2W') {
      this.isOtherTireLineDisabled = true;
      this.isOtherSeasonDisabled = true;
      this.isOtherTireUsageDisabled = false;
      this.getTyreOtherUsage(this.selectedOtherBrand, [], this.getPLCode());
    }

    this.getAllOtherBrandIds = this.selectedOtherBrand;
  }

  onDeSelectAllOtherBrands() {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('brandY')).clear();

    (<FormArray>this.multiStepForm.get('seasonY')).clear();
    this.otherSeasons = [];
    this.selectedOtherSeason = [];
    this.multiStepForm.patchValue({
      seasonY: null,
    });
    this.isOtherSeasonDisabled = true;

    (<FormArray>this.multiStepForm.get('usageY')).clear();
    this.otherTyreUsages = [];
    this.selectedOtherTyreUsage = [];
    this.multiStepForm.patchValue({
      usageY: null,
    });
    this.isOtherTireUsageDisabled = true;

    (<FormArray>this.multiStepForm.get('tirelineY')).clear();
    this.otherTirelines = [];
    this.selectedOtherTireline = [];
    this.multiStepForm.patchValue({
      tirelineY: null,
    });
    this.isOtherTireLineDisabled = true;
  }

  /* Step 1 other brands section */
  onOtherSeasonSelect(item: any) {
    this.isMultiStepFormDirty = true;
    const control = new FormControl(item);
    (<FormArray>this.multiStepForm.get('seasonY')).push(control);

    //** */
    (<FormArray>this.multiStepForm.get('usageY')).clear();
    this.selectedOtherTyreUsage = [];
    this.multiStepForm.patchValue({
      usageY: null,
    });
    this.otherTyreUsages = [];

    (<FormArray>this.multiStepForm.get('tirelineY')).clear();
    this.selectedOtherTireline = [];
    this.multiStepForm.patchValue({
      tirelineY: null,
    });

    this.relativeActionOnOtherSeason();
  }

  relativeActionOnOtherSeason() {
    if (
      this.multiStepForm.get('seasonY').value.length > 0 &&
      this.getPLCode() != 'AG' && this.getPLCode() != '2W'
      && this.getPLCode() != 'CT' ) {
      this.isOtherTireUsageDisabled = false;
    }

    this.otherTirelines = [];
    //** */

    let data = this.selectedOtherBrand;
    this.getOtherBrandIds = data.map((item: any) => {
      return item.brandId;
    });

    this.otherAlllist = [];

    let data2 = this.selectedOtherSeason;
    for (let j of data2) {
      let data3 = this.otherMasterData.subSeasonMap[j];
      for (const element of data3) {
        this.otherAlllist.push(element);
      }
    }

    for (const element of this.otherAlllist) {
      this.checkElementNotNull(element);
      this.getAllOthersubSeasonIdsIn = this.otherAlllist.map((item: any) => {
        return item.subSeasonId;
      });
    }

    this.spinnerService.show();

    if (this.getPLCode() != 'AG' && this.getPLCode() != '2W' && this.getPLCode() != 'CT') {
      this.getTyreOtherUsage(
        this.getOtherBrandIds,
        this.getAllOthersubSeasonIdsIn,
        this.getPLCode()
      );
    }

    this.otherAlllist = [];
  }

  relativeActionOnOtherTyreUsage() {
    if (this.multiStepForm.get('usageY').value.length > 0) {
      this.isOtherTireLineDisabled = false;
    }

    let data = this.selectedOtherBrand;
    this.getOtherBrandIds = data.map((item: any) => {
      return item.brandId;
    });

    let data2 = this.selectedOtherSeason;

    for (let j of data2) {
      let data3 = this.otherMasterData.subSeasonMap[j];
      for (const element of data3) {
        this.otherAlllist.push(element);
      }
    }

    this.otherAlllist = [];

    for (const element of this.otherAlllist) {
      this.checkElementNotNull(element);
      this.getAllOthersubSeasonIdsIn = this.otherAlllist.map((item: any) => {
        return item.subSeasonId;
      });
    }

    let data4 = this.selectedOtherTyreUsage;
    this.getAllOtherusageIdsIn = data4.map((item: any) => {
      return item.usageId;
    });

    this.spinnerService.show();

    // other tyre lines call
    if (this.getPLCode() != 'AG' && this.getPLCode() != '2W' && this.getPLCode() != 'CT') {
      this.getOtherTyreLines(
        this.getOtherBrandIds,
        this.getAllOthersubSeasonIdsIn,
        this.getAllOtherusageIdsIn
      );
    } else if (this.getPLCode() == '2W') {
      this.getOtherTyreLines(
        this.getOtherBrandIds,
        [],
        this.getAllOtherusageIdsIn
      );
    }

    this.otherAlllist = [];
  }

  onOtherSeasonDeselect(item: any) {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('usageY')).clear();
    this.selectedOtherTyreUsage = [];
    this.multiStepForm.patchValue({
      usageY: null,
    });
    this.otherTyreUsages = [];

    (<FormArray>this.multiStepForm.get('tirelineY')).clear();
    this.selectedOtherTireline = [];
    this.multiStepForm.patchValue({
      tirelineY: null,
    });
    this.otherTirelines = [];

    if (item.length > 0 && this.getPLCode() != 'AG' && this.getPLCode() != 'CT') {
      (<FormArray>this.multiStepForm.get('usageY')).clear();
      this.isOtherTireUsageDisabled = false;
    }

    let i = 0;
    (<FormArray>this.multiStepForm.get('seasonY')).controls.forEach(
      (l: any) => {
        if (l.value == item) {
          (<FormArray>this.multiStepForm.get('seasonY')).removeAt(i);
          return;
        }
        i++;
      }
    );

    let data = this.selectedOtherBrand;
    this.getOtherBrandIds = data.map((item: any) => {
      return item.brandId;
    });

    let data2 = this.selectedOtherSeason;

    this.otherAlllist = [];

    for (let j of data2) {
      let data3 = this.otherMasterData.subSeasonMap[j];
      for (const element of data3) {
        this.otherAlllist.push(element);
      }
    }

    if (this.otherAlllist.length !== 0) {
      for (const element of this.otherAlllist) {
        this.checkElementNotNull(element);
        this.getAllOthersubSeasonIdsIn = this.otherAlllist.map((item: any) => {
          return item.subSeasonId;
        });
      }
    } else {
      this.getAllOthersubSeasonIdsIn = [];
    }
    this.spinnerService.show();

    if (this.getPLCode() != 'AG' && this.getPLCode() != '2W' && this.getPLCode() != 'CT') {
      this.getTyreOtherUsage(
        this.getOtherBrandIds,
        this.getAllOthersubSeasonIdsIn,
        this.getPLCode()
      );
    }
  }

  onSelectAllOtherSeasons(items: any) {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('seasonY')).clear();
    (<FormArray>this.multiStepForm.get('usageY')).clear();

    for (const element of items) {
      const control = new FormControl(element);

      (<FormArray>this.multiStepForm.get('seasonY')).push(control);
    }

    //** */
    (<FormArray>this.multiStepForm.get('usageY')).clear();
    this.selectedOtherTyreUsage = [];
    this.multiStepForm.patchValue({
      usageY: null,
    });
    this.otherTyreUsages = [];

    (<FormArray>this.multiStepForm.get('tirelineY')).clear();
    this.selectedOtherTireline = [];
    this.multiStepForm.patchValue({
      tirelineY: null,
    });
    this.otherTirelines = [];
    //** */

    if (this.getPLCode() != 'AG' && this.getPLCode() != 'CT') {
      this.isOtherTireUsageDisabled = false;
    }

    let data = this.selectedOtherBrand;

    this.getOtherBrandIds = data.map((item: any) => {
      return item.brandId;
    });

    this.otherSeasonitems = items;
    this.otherAlllist = [];

    for (let j of items) {
      let data3 = this.otherMasterData.subSeasonMap[j];
      for (const element of data3) {
        this.otherAlllist.push(element);
      }
    }

    for (const element of this.otherAlllist) {
      this.checkElementNotNull(element);
      this.getAllOthersubSeasonIdsIn = this.otherAlllist.map((item: any) => {
        return item.subSeasonId;
      });
    }

    this.spinnerService.show();

    if (this.getPLCode() != 'AG' && this.getPLCode() != '2W' && this.getPLCode() != 'CT') {
      this.getTyreOtherUsage(
        this.getOtherBrandIds,
        this.getAllOthersubSeasonIdsIn,
        this.getPLCode()
      );
    }
  }

  onDeSelectAllOtherSeasons() {
    this.isMultiStepFormDirty = true;
    if (this.getPLCode() != 'AG' && this.getPLCode() != 'CT') {
      this.isOtherTireUsageDisabled = false;
    }
    this.isOtherTireLineDisabled = false;
    (<FormArray>this.multiStepForm.get('seasonY')).clear();
    this.selectedOtherSeason = [];

    (<FormArray>this.multiStepForm.get('usageY')).clear();
    this.otherTyreUsages = [];
    this.selectedOtherTyreUsage = [];
    this.multiStepForm.patchValue({
      usageY: null,
    });
    this.isOtherTireUsageDisabled = true;

    (<FormArray>this.multiStepForm.get('tirelineY')).clear();
    this.otherTirelines = [];
    this.selectedOtherTireline = [];
    this.multiStepForm.patchValue({
      tirelineY: null,
    });
    this.isOtherTireLineDisabled = true;
  }

  onOtherTyreUsageSelect(item: any) {
    this.isMultiStepFormDirty = true;
    const control = new FormControl(item.usageName);
    (<FormArray>this.multiStepForm.get('usageY')).push(control);

    this.multiStepForm.patchValue({
      usageY: this.selectedOtherTyreUsage.usageName,
    });

    this.relativeActionOnOtherTyreUsage();
  }

  onOtherTyreUsageDeselect(item: any) {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('tirelineY')).clear();
    this.selectedOtherTireline = [];
    this.multiStepForm.patchValue({
      tirelineY: null,
    });

    if (item.length > 0) {
      (<FormArray>this.multiStepForm.get('tirelineY')).clear();
      this.isOtherTireLineDisabled = false;
    }

    let i = 0;
    (<FormArray>this.multiStepForm.get('usageY')).controls.forEach((l: any) => {
      if (l.value == item.usageName) {
        (<FormArray>this.multiStepForm.get('usageY')).removeAt(i);
        return;
      }
      i++;
    });

    // received usages
    let usagesData = this.selectedOtherTyreUsage;
    this.getOtherUsages = usagesData.map((item: any) => {
      return item.usageId;
    });

    // received brands
    let data = this.selectedOtherBrand;
    this.getOtherBrandIds = data.map((item: any) => {
      return item.brandId;
    });

    let data2 = this.selectedOtherSeason;

    this.otherAlllist = [];

    for (let j of data2) {
      let data3 = this.otherMasterData.subSeasonMap[j];
      for (const element of data3) {
        this.otherAlllist.push(element);
      }
    }

    if (this.otherAlllist.length !== 0) {
      for (const element of this.otherAlllist) {
        this.checkElementNotNull(element);
        this.getAllOthersubSeasonIdsIn = this.otherAlllist.map((item: any) => {
          return item.subSeasonId;
        });
      }
    } else {
      this.getAllOthersubSeasonIdsIn = [];
    }

    this.spinnerService.show();

    // other tyre lines call
    if (this.getPLCode() != 'AG' && this.getPLCode() != '2W' && this.getPLCode() != 'CT') {
      this.getOtherTyreLines(
        this.getOtherBrandIds,
        this.getAllOthersubSeasonIdsIn,
        this.getOtherUsages
      );
    } else if (this.getPLCode() == '2W') {
      this.getOtherTyreLines(this.getOtherBrandIds, [], this.getOtherUsages);
    }
  }

  onSelectAllOtherTyreUsages(items: any) {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('usageY')).clear();
    (<FormArray>this.multiStepForm.get('tirelineY')).clear();
    for (let key of items) {
      const controls = new FormControl(key.usageName);
      (<FormArray>this.multiStepForm.get('usageY')).push(controls);
    }

    this.isOtherTireLineDisabled = false;

    let usageStore: any = [];
    for (let key of items) {
      const controls = new FormControl(key.usageId);
      usageStore.push(controls.value);
    }
    this.selectedOtherTyreUsage = usageStore;

    let data = this.selectedOtherBrand;
    this.getOtherBrandIds = data.map((item: any) => {
      return item.brandId;
    });

    for (let j of this.otherSeasonitems) {
      let data3 = this.otherMasterData.subSeasonMap[j];
      for (const element of data3) {
        this.otherAlllist.push(element);
      }
    }

    this.otherAlllist = [];

    for (const element of this.otherAlllist) {
      this.checkElementNotNull(element);
      this.getAllOthersubSeasonIdsIn = this.otherAlllist.map((item: any) => {
        return item.subSeasonId;
      });
    }

    this.spinnerService.show();

    if (this.getPLCode() != 'AG' && this.getPLCode() != '2W' && this.getPLCode() != 'CT') {
      this.getOtherTyreLines(
        this.getOtherBrandIds,
        this.getAllOthersubSeasonIdsIn,
        this.selectedOtherTyreUsage
      );
    } else if (this.getPLCode() == '2W') {
      this.getOtherTyreLines(
        this.getOtherBrandIds,
        [],
        this.selectedOtherTyreUsage
      );
    }
  }

  onDeSelectAllOtherTyreUsages(items: any) {
    this.isOtherTireLineDisabled = true;
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('usageY')).clear();
    (<FormArray>this.multiStepForm.get('tirelineY')).clear();
    this.otherTirelines = [];
    this.selectedOtherTireline = null;
    this.multiStepForm.patchValue({
      tirelineY: this.selectedOtherTireline.categoryName,
    });

    let arr: any = [];

    for (let key of items) {
      const controls = new FormControl(key.usageId);
      arr.push(JSON.stringify(controls.value));
    }
    this.selectedOtherTyreUsage = arr;

    this.spinnerService.show();

    if (this.getPLCode() != 'AG' && this.getPLCode() != '2W' && this.getPLCode() != 'CT') {
      this.getOtherTyreLines(
        this.getAllOtherBrandIds,
        this.getAllOtherSelectedSeason,
        this.selectedOtherTyreUsage
      );
    } else if (this.getPLCode() == '2W') {
      this.getOtherTyreLines(
        this.getAllOtherBrandIds,
        [],
        this.selectedOtherTyreUsage
      );
    }
  }

  /* Step 1 end Other Brand section */

  /* Step 4 data */
  onPriceTypeSelect(item: any) {
    this.getPriceTypeParameterLabel(item);
    this.isMultiStepFormDirty = true;
    this.isPriceTypeValidation = true;
    const control = new FormControl(
      this.priceTypeParameterLabel,
      Validators.required
    );
    (<FormArray>this.multiStepForm.get('priceType')).push(control);
    this.multiStepForm.patchValue({
      priceType: this.selectedPriceType.parameterName,
    });
    if (this.selectedPriceType.parameterName === 'sell_in') {
      this.isOrderPrefDisable = true;
    }
    this.checkPriceTypelabel();
  }

  getPriceTypeParameterLabel(item: any) {
    for (let key of this.priceTypes) {
      if (item.parameterName === key.parameterName) {
        this.priceTypeParameterLabel = key.parameterLabel;
      }
    }
  }

  onPriceTypeDeselect(item: any) {
    this.getPriceTypeParameterLabel(item);
    this.isMultiStepFormDirty = true;
    let i = 0;
    (<FormArray>this.multiStepForm.get('priceType')).controls.forEach(
      (l: any) => {
        if (l.value == this.priceTypeParameterLabel) {
          (<FormArray>this.multiStepForm.get('priceType')).removeAt(i);
          this.isOrderPrefDisable = false;
          return;
        }
        i++;
      }
    );
  }

  onSelectAllPriceTypes(items: any) {
    this.isMultiStepFormDirty = true;
    this.isPriceTypeValidation = true;
    (<FormArray>this.multiStepForm.get('priceType')).clear();
    for (let pricetype of this.priceTypes) {
      this.priceTypeParameterLabel = pricetype.parameterLabel;
      const controls = new FormControl(this.priceTypeParameterLabel);
      (<FormArray>this.multiStepForm.get('priceType')).push(controls);
    }
    this.checkPriceTypelabel();
  }

  onDeSelectAllPriceType() {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('priceType')).clear();
    this.checkPriceTypelabel();
  }

  onOrderPreferenceSelect(item: any) {
    this.getOrderPreferenceParameterLabel(item);
    this.isMultiStepFormDirty = true;
    this.isOrderPreferenceValidation = true;
    const control = new FormControl(
      this.orderPreferenceParameterLabel,
      Validators.required
    );
    (<FormArray>this.multiStepForm.get('orderPreference')).push(control);
    this.multiStepForm.patchValue({
      orderPreference: this.selectedOrderPreference.parameterName,
    });
    this.checkOrderPreferencelabel();
  }

  getOrderPreferenceParameterLabel(item: any) {
    for (let key of this.orderPreferences) {
      if (item.parameterName === key.parameterName) {
        this.orderPreferenceParameterLabel = key.parameterLabel;
      }
    }
  }

  checkOrderPreferencelabel() {
    if (this.orderPreferenceParameterLabel === 'most_popular') {
      this.multiStepForm.patchValue({
        mostPopular: 'True',
        mostRecent: 'False',
      });
    } else if (this.orderPreferenceParameterLabel === 'most_recent') {
      this.multiStepForm.patchValue({
        mostRecent: 'True',
        mostPopular: 'False',
      });
    }
  }

  checkPriceTypelabel() {
    if (this.priceTypeParameterLabel === 'sell_in') {
      this.selectedOrderPreference = [];
      this.onOrderPreferenceDeselect(null);
      this.onDeSelectAllOrderPreferences();
      (<FormArray>this.multiStepForm.get('orderPreference')).clear();
      this.isOrderPrefDisable = true;
      this.multiStepForm.patchValue({
        orderPreference: null,
        mostPopular: 'False',
        mostRecent: 'False',
      });
    }
  }

  onOrderPreferenceDeselect(item: any) {
    if (item != null) {
      this.getOrderPreferenceParameterLabel(item);
    }

    this.isMultiStepFormDirty = true;
    let i = 0;
    (<FormArray>this.multiStepForm.get('orderPreference')).controls.forEach(
      (l: any) => {
        if (l.value == this.orderPreferenceParameterLabel) {
          (<FormArray>this.multiStepForm.get('orderPreference')).removeAt(i);
          if (this.orderPreferenceParameterLabel === 'most_popular') {
            this.multiStepForm.patchValue({
              mostPopular: 'False',
            });
          } else if (this.orderPreferenceParameterLabel === 'most_recent') {
            this.multiStepForm.patchValue({
              mostRecent: 'False',
            });
          }
          return;
        }
        i++;
      }
    );
  }

  onSelectAllOrderPreferences(items: any) {
    this.isMultiStepFormDirty = true;
    this.isOrderPreferenceValidation = true;
    (<FormArray>this.multiStepForm.get('orderPreference')).clear();

    for (let orderPreference of this.orderPreferences) {
      this.orderPreferenceParameterLabel = orderPreference.parameterLabel;
      const controls = new FormControl(this.orderPreferenceParameterLabel);
      (<FormArray>this.multiStepForm.get('orderPreference')).push(controls);
      this.checkOrderPreferencelabel();
    }
  }

  onDeSelectAllOrderPreferences() {
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('orderPreference')).clear();
    this.checkOrderPreferencelabel();
  }

  /* End Step 4 data */

  handleClusterValues(e: any, parameterLabel: any) {
    this.isMultiStepFormDirty = true;

    //----reset Degrardation file attributes----
    this.getFileName = undefined;
    this.isButtonDisabledDeg = true;
    this.successMessageDeg = false;
    this.getFileSize = false;
    this.errorFlagDeg = false;

    const control = new FormControl(parameterLabel, Validators.required);

    if (e.target.checked) {
      (<FormArray>this.multiStepForm.get('clustering')).push(control);
      this.clusteringData = this.multiStepForm.get('clustering').value;

      for (let key of this.clusterings) {
        if (e.target.value === key.parameterName) {
          key.isSelected = true;
        }
      }
      this.utilService.getClusteringCheckValues(this.clusteringData);
    } else {
      for (let key of this.clusterings) {
        if (e.target.value === key.parameterName) {
          key.isSelected = false;
        }
      }
      let i = 0;
      (<FormArray>this.multiStepForm.get('clustering')).controls.forEach(
        (val: any) => {
          if (val.value == parameterLabel) {
            (<FormArray>this.multiStepForm.get('clustering')).removeAt(i);
            return;
          }
          i++;
        }
      );

      this.clusteringData = this.multiStepForm.get('clustering').value;
      this.utilService.getClusteringCheckValues(this.clusteringData);
    }

    const commonItems = this.clusteringData.filter((item: any) =>
      new Set(this.degradationData).has(item)
    );

    this.degradationData = this.degradationData.filter(
      (val: any) => this.clusteringData.indexOf(val) == -1
    );
    if(this.getPLCode() === 'TC') {
      if (this.clusteringData.includes('usage')) {
        //this.intersection.push('macro_usage');
        this.isMacroUsageSelected = false;
      } else if(this.clusteringData.includes('macro_usage')) {
        //this.intersection.push('usage');
        this.isMacroUsageSelected = true;
      }
    }

    let i = 0;
    (<FormArray>this.multiStepForm.get('degradation')).controls.forEach(
      (val: any) => {
        if (val.value == commonItems) {
          (<FormArray>this.multiStepForm.get('degradation')).removeAt(i);
          return;
        }
        i++;
      }
    );

    this.removeDegradationFile();
    this.multiStepForm.patchValue({
      ruleSheet: null,
    });
  }

  handleDegradationValues(e: any, parameterLabel: any) {
    this.setInitialDegradationState();

    if (e.target.checked) {
      this.addDegradation(parameterLabel, e.target.value);
    } else {
      this.removeDegradation(parameterLabel, e.target.value);
    }

    this.updateLoadIntersection(parameterLabel);
  }

  private setInitialDegradationState() {
    this.isMultiStepFormDirty = true;
    this.isButtonDisabledDeg = true;
    this.successMessageDeg = false;
    this.getFileName = null;
    this.getFileSize = null;
    this.multiStepForm.patchValue({ ruleSheet: null });
  }

  private addDegradation(parameterLabel: string, targetValue: string) {
    const control = new FormControl(parameterLabel, Validators.required);
    (<FormArray>this.multiStepForm.get('degradation')).push(control);

    this.updateDegradationData();
    this.selectDegradation(targetValue, true);
    this.removeDegradationFile();
  }

  private removeDegradation(parameterLabel: string, targetValue: string) {
    this.selectDegradation(targetValue, false);
    this.removeDegradationControl(parameterLabel);

    this.removeDegradationFile();
    this.updateDegradationData();
    this.relativeActionOnDegradationDataLength();
  }

  private updateDegradationData() {
    this.degradationData = this.multiStepForm.get('degradation').value;
    this.utilService.getDegradationCheckValues(this.degradationData);
  }

  private selectDegradation(targetValue: string, isSelected: boolean) {
    for (let key of this.degradations) {
      if (targetValue === key.parameterName) {
        if (isSelected) {
          key.isSelected = true;
          this.isDownloadBoxDisabled = true;
        } else {
          key.isSelected = false;
          this.degradationData.pop(key.parameterName);
        }
      }
    }
  }

  private removeDegradationControl(parameterLabel: string) {
    const degradationArray = <FormArray>this.multiStepForm.get('degradation');
    degradationArray.controls.forEach((val: any, i: number) => {
      if (val.value === parameterLabel) {
        degradationArray.removeAt(i);
        return;
      }
    });
  }

  private updateLoadIntersection(parameterLabel: string) {
    if (parameterLabel === 'load_index') {
      this.updateLoadIndexIntersection();
    }

    if (
      parameterLabel === 'load_range' ||
      parameterLabel === 'tyre_ply_rating'
    ) {
      this.updateLoadRangeIntersection();
    }
  }

  private updateLoadIndexIntersection() {
    if (this.degradationData.includes('load_index')) {
      this.Loadintersection.push(
        this.getPLCode() === 'AG' ? 'Ply Rating' : 'Load Range'
      );
    } else {
      this.Loadintersection.pop();
    }
  }

  private updateLoadRangeIntersection() {
    if (
      this.degradationData.includes('load_range') ||
      this.degradationData.includes('tyre_ply_rating')
    ) {
      this.Loadintersection.push(
        this.getPLCode() === 'PL' ? 'Load Index Single' : 'Load Index'
      );
    } else {
      this.Loadintersection.pop();
    }
  }

  relativeActionOnDegradationDataLength() {
    this.isButtonDisabledDeg = true;
    this.isDownloadBoxDisabled = true;

    if (this.degradationData.length < 1) {
      this.isDownloadBoxDisabled = false;
      this.isDegradationSelected = true;
      this.isButtonDisabledDeg = true;
      this.successMessageDeg = false;
      this.getFileName = null;
      this.getFileSize = null;

      this.removeDegradationFile();
    }
  }

  downloadDegradationFile() {
    this.spinnerService.show();
    const clustering1 = this.clusteringData;
    const degradation1 = this.degradationData;

    const clustering = JSON.parse(JSON.stringify(clustering1));
    const degradation = JSON.parse(JSON.stringify(degradation1));

    const userId: any = this.userId.toString();
    const regionId = this.storeRegionId;

    let formData = new FormData();
    formData.append('userId', userId);

    this.pairingStepFormApi
      .getDegradationFile(
        userId,
        clustering,
        degradation,
        regionId,
        this.getPLCode()
      )
      .subscribe(
        (response: HttpResponse<Blob>) => {
          this.spinnerService.hide();

          const contentDispositionHeader: string = response.headers
            .get('Content-Disposition')
            .split('filename=')[1]
            .split(';')[0];

          const blob = new Blob([response.body], {
            type: 'application/vnd.ms-excel',
          });
          const file = new File([blob], contentDispositionHeader, {
            type: 'application/vnd.ms-excel',
          });
          const url = window.URL.createObjectURL(blob);
          FileSaver.saveAs(blob, contentDispositionHeader);
          if (url.length === 0) console.log(file.size + url.length);
        },
        (error) => {
          this.spinnerService.hide();
        }
      );
  }

  downloadCompetitorFile() {
    this.spinnerService.show();
    const userId: any = this.id.toString();

    let formData = new FormData();
    formData.append('userId', userId);
    formData.append('productLineCode ', this.getPLCode());

    this.pairingStepFormApi.getCompetitorFile(formData).subscribe(
      (response: HttpResponse<any>) => {
        this.spinnerService.hide();

        // const contentDispositionHeader: string = response.headers
        this.contentDispositionHeader = response.headers
          .get('Content-Disposition')
          .split('filename=')[1]
          .split(';')[0];

        const blob = new Blob([response.body], {
          type: 'application/vnd.ms-excel',
        });
        const file = new File([blob], this.contentDispositionHeader, {
          type: 'application/vnd.ms-excel',
        });
        const url = window.URL.createObjectURL(blob);
        FileSaver.saveAs(blob, this.contentDispositionHeader);
        if (url.length === 0) console.log(file.size + url.length);
      },
      (error) => {
        this.spinnerService.hide();
      }
    );
  }
  

  downloadCAIFile() {
    this.downloadFileService.downloadCAIFile(this.userId);
  }

  public droppedDegradationFile(files: NgxFileDropEntry[]) {
    if (files.length > 1) {
      return;
    }
    this.isMultiStepFormDirty = true;
    const file = files[0];
    console.log(file);
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file

          this.getFileName = file.name;
          this.getFileSize = file.size;

          this.spinnerService.show();
          let formData = new FormData();
          formData.append('file', file);
          formData.append('clustering', this.clusteringData);
          formData.append('degradation', this.degradationData);
          formData.append('regionId', this.storeRegionId);
          formData.append('productLineCode', this.getPLCode());

          this.pairingStepFormApi
            .uploadDegradationFile(formData, this.userId)
            .subscribe(
              (res: any) => {
                this.spinnerService.hide();
                const getFileResponse: any = res;

                if (getFileResponse.message === 'OK') {
                  const degFileResult = getFileResponse.result;
                  this.successDegradationFile = degFileResult;
                  this.multiStepForm.patchValue({
                    ruleSheet: this.successDegradationFile,
                  });

                  this.removeDegradationFile();

                  this.inputFilesFormControls.push(
                    this.fb.group({
                      fileName: this.successDegradationFile,
                      fileTypeId: 1,
                    })
                  );
                  this.isDegradationSelected = false;
                  this.isButtonDisabledDeg = false;
                  this.successMessageDeg = true;
                  this.errorFlagDeg = false;
                }
              },
              (error: any) => {
                this.spinnerService.hide();
                this.errorFlagDeg = true;
                this.degradationError = this.getSharingErorr;
                this.successMessageDeg = false;
                this.isButtonDisabledDeg = true;
                this.isDegradationSelected = true;
              }
            );
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(fileEntry.name);
      }
    }
  }

  removeDegradationFile() {
    for (
      let i = 0;
      i < this.multiStepForm.get('inputFiles').value.length;
      i++
    ) {
      if (
        i == 0 &&
        this.multiStepForm.get('inputFiles').value.at(i).fileTypeId == 1
      ) {
        this.inputFilesFormControls.removeAt(0);
      }
      if (
        i == 1 &&
        this.multiStepForm.get('inputFiles').value.at(i).fileTypeId == 1
      ) {
        this.inputFilesFormControls.removeAt(1);
      }
      if (
        i == 2 &&
        this.multiStepForm.get('inputFiles').value.at(i).fileTypeId == 1
      ) {
        this.inputFilesFormControls.removeAt(2);
      }
    }
  }

  public droppedCompetitorFile(files: NgxFileDropEntry[]) {
    if (files.length > 1) {
      return;
    }
    this.isMultiStepFormDirty = true;
    const file = files[0];
    console.log(file.fileEntry.name);
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file

          this.getFileNameCompetitor = file.name;
          this.getFileSizeCompetitor = file.size;

          this.spinnerService.show();
          let formData = new FormData();
          formData.append('file', file);

          this.pairingStepFormApi
            .uploadCompetitorFile(formData, this.userId)
            .subscribe(
              (res: any) => {
                this.spinnerService.hide();

                const getFileResponse: any = res;
                if (getFileResponse.message === 'OK') {
                  this.successCompetitorFile = getFileResponse.result;
                  this.multiStepForm.patchValue({
                    competitorsPrioritizationTable: this.successCompetitorFile,
                  });

                  for (
                    let i = 0;
                    i < this.multiStepForm.get('inputFiles').value.length;
                    i++
                  ) {
                    if (
                      i == 0 &&
                      this.multiStepForm.get('inputFiles').value.at(i)
                        .fileTypeId == 2
                    ) {
                      this.inputFilesFormControls.removeAt(0);
                    }
                    if (
                      i == 1 &&
                      this.multiStepForm.get('inputFiles').value.at(i)
                        .fileTypeId == 2
                    ) {
                      this.inputFilesFormControls.removeAt(1);
                    }
                    if (
                      i == 2 &&
                      this.multiStepForm.get('inputFiles').value.at(i)
                        .fileTypeId == 2
                    ) {
                      this.inputFilesFormControls.removeAt(2);
                    }
                  }

                  this.inputFilesFormControls.push(
                    this.fb.group({
                      fileName: this.successCompetitorFile,
                      fileTypeId: 2,
                    })
                  );

                  this.isButtonDisabled = false;
                  this.successMessageComp = true;
                  this.errorFlagComp = false;
                }
              },
              (error: any) => {
                this.spinnerService.hide();
                this.errorFlagComp = true;
                this.competitorError = this.getSharingErorr;
                this.successMessageComp = false;
                this.isButtonDisabled = true;
              }
            );
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(fileEntry.name);
      }
    }
  }

  public droppedCAIFile(files: NgxFileDropEntry[]) {
    if (files.length > 1) {
      return;
    }

    this.isMultiStepFormDirty = true;

    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file

          this.getCAIFileName = file.name;
          this.getCAIFileSize = file.size;

          const userId: any = this.userId.toString();

          this.spinnerService.show();
          let formData = new FormData();

          formData.append('file', file);
          formData.append('userId', userId);
          formData.append('productLineCode', this.getPLCode());

          this.pairingStepFormApi.uploadCAIFile(formData).subscribe(
            (response: any) => {
              this.spinnerService.hide();
              const getFileResponse: any = response;
              if (
                getFileResponse.body.message === 'OK' &&
                getFileResponse.body.totalCount === 2
              ) {
                this.successCAIFile = getFileResponse.body.result;

                this.currentActionType = this.UNMATCHED_CAI_DOWNLOAD;
                this.popupMessage = PairingConst.UNMATCHED_CAI_DOWNLOAD_MSG;
                this.isMissingCAIDownloadPopup = true;
                this.displayStyle = 'block';

                this.multiStepForm.patchValue({
                  caiTable: this.successCAIFile,
                });

                this.setUpdatedCommonFile();

                this.inputFilesFormControls.push(
                  this.fb.group({
                    fileName: this.successCAIFile,
                    fileTypeId: 5,
                  })
                );

                this.isCAIFileMissing = false;
                this.isButtonDisabled = false;
                this.successMessageCAI = true;
                this.errorFlagCAI = false;
              } else if (getFileResponse.body.message === 'OK') {
                this.successCAIFile = getFileResponse.body.result;

                this.multiStepForm.patchValue({
                  caiTable: this.successCAIFile,
                });

                this.setUpdatedCommonFile();

                this.inputFilesFormControls.push(
                  this.fb.group({
                    fileName: this.successCAIFile,
                    fileTypeId: 5,
                  })
                );

                this.isButtonDisabled = false;
                this.successMessageCAI = true;
                this.errorFlagCAI = false;
                this.isCAIFileMissing = false;
              }
            },
            (error: any) => {
              this.spinnerService.hide();
              this.errorFlagCAI = true;
              this.caiError = this.getSharingErorr;
              this.successMessageCAI = false;
              this.isButtonDisabled = true;
              this.isCAIFileMissing = false;
            }
          );
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(fileEntry.name);
      }
    }
  }

  setUpdatedCommonFile() {
    for (
      let i = 0;
      i < this.multiStepForm.get('inputFiles').value.length;
      i++
    ) {
      if (
        i == 0 &&
        this.multiStepForm.get('inputFiles').value.at(i).fileTypeId == 5
      ) {
        this.inputFilesFormControls.removeAt(0);
      }
      if (
        i == 1 &&
        this.multiStepForm.get('inputFiles').value.at(i).fileTypeId == 5
      ) {
        this.inputFilesFormControls.removeAt(1);
      }
      if (
        i == 2 &&
        this.multiStepForm.get('inputFiles').value.at(i).fileTypeId == 5
      ) {
        this.inputFilesFormControls.removeAt(2);
      }
    }
  }

  caiFileDownload() {
    this.displayStyle = 'none';
    this.isMissingCAIDownloadPopup = false;
    this.isCAIFileMissing = false;
    this.downloadFileService.downloadMissingCAI(this.successCAIFile);
  }

  public fileOver(event: any) {
    console.log(event);
  }

  public fileLeave(event: any) {
    console.log(event);
  }

  goBack() {
    this.step = this.step - 1;
    if (this.step > this.maxStep) this.maxStep = this.step;

    this.stepLabelText = this.getCurrentStepLabelText(this.step);
    this.onSaveDraft(false);
  }

  getCurrentStepLabelText(_step: number) {
    return new Map(PairingConst.stepMap.map((i) => [i.step, i.stage])).get(
      _step
    );
  }

  goNext() {
    this.isClickAction = true;
    this.formSubmitted = true;
    this.step = this.step + 1;

    if (this.step == 1) {
      this.submitted = true;
    }

    if (this.step == 2) {
      if (!this.clusteringData) {
        this.clusterings.forEach((cluster: any) => {
          if (cluster.isDefaultSelected) {
            this.clusteringData.push(cluster.parameterLabel);
          }
        });
      }
      this.spinnerService.show();

      // getting selected clustering values
      this.stepLabelText = 'Clustering';
      this.relativeActionOnClusteringPopulation();
    }

    if (this.step == 3) {
      this.isDegradationSelected = false;
      this.spinnerService.show();

      // getting clustering selected values
      this.utilService.clusteringDataSource.subscribe((res) => {
        this.getSelectedClusteringValues = res;
        this.getSelectedClusteringValues = this.clusteringData;
      });

      this.stepLabelText = 'Degradation';
      this.isDegradationSelected = false;
      this.relativeActionOnDegradationPopulation();
    }

    if (this.step == 4) {
      this.priceAndPreferenceData();
    }
    if (this.step > this.maxStep) this.maxStep = this.step;
  }

  priceAndPreferenceData() {
    let plCode = this.getPLCode();
    if (this.multiStepForm.get('priceType')) {
      this.stepLabelText = 'Price Type';
      this.pairingStepFormApi
        .getClusteringValues(this.storeRegionId, this.stepLabelText, plCode)
        .subscribe(
          (res) => {
            this.spinnerService.hide();
            const priceTypeLists = res;
            this.priceTypes = priceTypeLists.result;

            this.priceTypes = this.priceTypes.filter(function (el: any) {
              return el !== 'NULL';
            });

            if (this.editMode) {
              this.selectedPriceType = PairingHelper.getSelectedRecords(
                this.priceTypes,
                this.getDraftRecordById?.priceType ?? [],
                'parameterLabel'
              );
            }
          },
          (err: any) => {
            this.spinnerService.hide();
            console.log(err);
          }
        );
    }
    if (this.multiStepForm.get('orderPreference')) {
      this.stepLabelText = 'Order Preference';
      let plCode = this.getPLCode();
      this.pairingStepFormApi
        .getClusteringValues(this.storeRegionId, this.stepLabelText, plCode)
        .subscribe(
          (res) => {
            this.spinnerService.hide();
            const orderPreferenceLists = res;
            this.orderPreferences = orderPreferenceLists.result;

            this.orderPreferences = this.orderPreferences.filter(function (
              el: any
            ) {
              return el !== 'NULL';
            });

            if (this.editMode) {
              this.selectedOrderPreference = PairingHelper.getSelectedRecords(
                this.orderPreferences,
                this.getDraftRecordById?.orderPreference ?? [],
                'parameterLabel'
              );
            }
            if (this.isClickAction) this.onSaveDraft(false);
          },
          (err: any) => {
            this.spinnerService.hide();
            console.log(err);
          }
        );
    }
  }

  relativeActionOnDegradationPopulation() {
    let plCode = this.getPLCode();
    this.pairingStepFormApi
      .getClusteringValues(this.storeRegionId, this.stepLabelText, plCode)
      .subscribe(
        (res) => {
          this.spinnerService.hide();
          this.degradations = res.result;

          if (this.editMode) {
            this.loadClusteringValues();
          }

          this.collectDegradationValues();
          this.updateIntersection();
          this.updateDegradationSelection();

          if (this.editMode) {
            this.handleEditMode();
          }

          if (this.isClickAction) {
            this.onSaveDraft(false);
          }
        },
        () => {
          this.spinnerService.hide();
        }
      );
  }

  private loadClusteringValues() {
    this.utilService.clusteringDataSource.subscribe((res) => {
      this.getSelectedClusteringValues = this.clusteringData = res;
    });
  }

  private collectDegradationValues() {
    this.getSelectedDegradationValues = this.degradations.map(
      (key: { parameterLabel: any }) =>
        new FormControl(key.parameterLabel).value
    );
  }

  private updateIntersection() {
    this.intersection = this.getSelectedClusteringValues.filter(
      (element: any) => this.getSelectedDegradationValues.includes(element)
    );
    this.intersection.forEach((item: any, index: string | number) => {
      const degradation = this.degradations.find(
        (degrad: { parameterLabel: any }) => degrad.parameterLabel === item
      ); 
      if (degradation) {
        this.intersection[index] = degradation.parameterName;
      }
    });
  }

  private updateDegradationSelection() {
    if (this.degradationData) {
      this.degradations.forEach(
        (degrad: { isSelected: any; parameterLabel: any }) => {
          degrad.isSelected = this.degradationData.includes(
            degrad.parameterLabel
          );
        }
      );
    }

    if (this.intersection) {
      this.degradations.forEach(
        (degrad: { parameterName: any; isSelected: boolean }) => {
          if (this.intersection.includes(degrad.parameterName)) {
            degrad.isSelected = false;
          }
        }
      );
    }
  }

  private handleEditMode() {
    if (this.degradationData) {
      this.utilService.getDegradationCheckValues(this.degradationData);
    }
    if (this.step == 4) {
      this.priceAndPreferenceData();
    }
  }

  relativeActionOnClusteringPopulation() {
    let plCode = this.getPLCode();
    this.pairingStepFormApi
      .getClusteringValues(this.storeRegionId, this.stepLabelText, plCode)
      .subscribe(
        (res: any) => {
          this.spinnerService.hide();
          this.processClusteringResponse(res);
        },
        (err: any) => {
          this.spinnerService.hide();
          console.log(err);
        }
      );
}

private processClusteringResponse(res: any) {
    this.clusterings = res.result;
    this.populateClusteringValues();
    this.updateClusteringData();
    this.markSelectedClusterings();
    this.handleClusteringEditMode();
    if (this.isClickAction) this.onSaveDraft(false);
}

private populateClusteringValues() {
    this.clusterings.forEach((cluster: any) => {
        const control = new FormControl(cluster.parameterLabel);
        this.getSelectedClusteringValues.push(control.value);
        if (this.shouldAddClusterToForm(cluster)) {
            (<FormArray>this.multiStepForm.get('clustering')).push(control);
        }
    });
}

private shouldAddClusterToForm(cluster: any): boolean {
    return cluster.isDefaultSelected &&
        (!this.clusteringData ||
            (this.clusteringData && !this.clusteringData.includes(cluster.parameterLabel)));
}

private updateClusteringData() {
    this.clusteringData = this.multiStepForm.get('clustering').value;
}

private markSelectedClusterings() {
    if (this.clusteringData) {
        this.clusteringData.forEach((key: any) => {
            const clustering = this.clusterings.find((clust: { parameterLabel: any; }) => 
              clust.parameterLabel === key);
            if (clustering) {
                clustering.isSelected = true;
            }
        });
    }
}

private handleClusteringEditMode() {
    if (this.editMode) {
        if (this.clusteringData) {
            this.utilService.getClusteringCheckValues(this.clusteringData);
        }
        if (this.step >= 3) {
            this.degradationAction();
        }
    }
}


  actionOnConfirmation() {
    if (this.currentActionType == this.SAVEDRAFT) this.onSaveDraft(true);
    else if (this.currentActionType == this.CLEARALL) {
      this.onClear();
      this.displayStyle = 'none';
      this.isMultiStepFormDirty = false;
      this.isConfirmationPopupVisibale = false;
    }
  }

  onClear() {
    switch (this.step) {
        case 1:
            this.clearStepOne();
            break;
        case 2:
            this.clearAllStepTwo();
            break;
        case 3:
            this.clearAllStepThree();
            break;
        case 4:
            this.clearAllStepfour();
            break;
        default:
            console.warn('Unknown step:', this.step);
    }
}

private clearStepOne() {
    this.clearFormArrays([
        'country', 'businessLine', 'productLine', 'brandX', 'seasonX', 'usageX', 
        'tirelineX', 'brandY', 'seasonY', 'usageY', 'tirelineY'
    ]);

    this.clearSelectedOptions();
    this.resetFlagsAndMessages();
    this.clearFormValues();
    this.clearCAIInputFiles();
    this.getOtherBrands(false, false);
}

private clearFormsArrays(fields: string[]) {
    fields.forEach(field => {
        (<FormArray>this.multiStepForm.get(field)).clear();
    });
}

private clearSelectedOptions() {
    this.selectedCountries = [];
    this.selectedBusinessLine = [];
    this.selectedProductLine = [];
    this.selectedMichelinBrand = [];
    this.selectedSeason = [];
    this.selectedTyreUsage = [];
    this.selectedTyreLine = [];
    this.selectedOtherBrand = [];
    this.selectedOtherSeason = [];
    this.selectedOtherTyreUsage = [];
    this.selectedOtherTireline = [];
}

private resetFlagsAndMessages() {
    this.isTireLineDisabled = true;
    this.isTireUsageDisabled = true;
    this.isSeasonDisabled = true;
    this.isBusinessLineSelected = false;
    this.isProductLineSelected = false;
    this.isOtherSeasonDisabled = true;
    this.isOtherTireUsageDisabled = true;
    this.isOtherTireLineDisabled = true;
    this.isRegionSelected = false;
    this.isCountryDisabledOnRegion = true;
    this.isProductLineDisabledOnRegion = true;
    this.isBusinessLineDisabledOnProductLine = true;
    this.successMessageCAI = false;
    this.getCAIFileName = null;
    this.getCAIFileSize = null;
    this.errorFlagCAI = null;
    this.caiError = null;
    this.isCAIChecked = false;
    this.isCAIFileMissing = false;
    this.CAIListMessage = '';
    this.isMichelinBrandsExcluded = false;
    this.isClearAllOnPerimeter = true;
}

private clearFormValues() {
    this.multiStepForm.patchValue({
        region: null,
        regionCode: null,
        country: null,
        businessLine: null,
        productLine: null,
        brandX: null,
        seasonX: null,
        usageX: null,
        tirelineX: null,
        brandY: null,
        tirelineY: null,
        seasonY: null,
        usageY: null,
        caiTable: null,
        isMichelinExcluded: this.isMichelinBrandsExcluded,
    });
}

private clearCAIInputFiles() {
    const inputFiles = this.multiStepForm.get('inputFiles').value;
    for (let i = 0; i < inputFiles.length; i++) {
        if (inputFiles[i].fileTypeId == 5) {
            this.inputFilesFormControls.removeAt(i);
        }
    }
}

  clearAllStepThree() {
    this.stepLabelText = 'Degradation';
    this.spinnerService.show();
    let plCode = this.getPLCode();
    this.pairingStepFormApi
      .getClusteringValues(this.storeRegionId, this.stepLabelText, plCode)
      .subscribe(
        (res) => {
          this.spinnerService.hide();
          const clusteringLists = res;
          this.degradations = clusteringLists.result;
        },
        (err: any) => {
          this.spinnerService.hide();
          console.log(err);
        }
      );

    this.onClearDegradation();
  }

  clearAllStepfour() {
    (<FormArray>this.multiStepForm.get('priceType')).clear();
    (<FormArray>this.multiStepForm.get('orderPreference')).clear();

    this.selectedPriceType = [];
    this.selectedOrderPreference = [];

    this.multiStepForm.patchValue({
      priceDate: null,
      pricePeriodStart: null,
      pricePeriodEnd: null,
      priceType: null,
      orderPreference: null,
      competitorsPrioritizationTable: null,
      valid_from_date: null,
      validFromDateValue: null,
    });

    for (
      let i = 0;
      i < this.multiStepForm.get('inputFiles').value.length;
      i++
    ) {
      if (
        i == 0 &&
        this.multiStepForm.get('inputFiles').value.at(i).fileTypeId == 2
      ) {
        this.inputFilesFormControls.removeAt(0);
      }
      if (
        i == 1 &&
        this.multiStepForm.get('inputFiles').value.at(i).fileTypeId == 2
      ) {
        this.inputFilesFormControls.removeAt(1);
      }
      if (
        i == 2 &&
        this.multiStepForm.get('inputFiles').value.at(i).fileTypeId == 2
      ) {
        this.inputFilesFormControls.removeAt(2);
      }
    }

    this.isPriceTypeValidation = false;
    this.isOrderPreferenceValidation = false;
    this.isPriceDateValidation = false;
    if (this.storeRegionCode != 'WW') {
      this.isOrderPrefDisable = false;
      this.isPriceMonthDisable = false;
      this.isPriceTypeDisable = false;
      this.isValidFromDateDisable = false;
    }
    this.isCompetitorChecked = false;
    this.competitorError = null;
    this.errorFlagComp = false;
    this.successMessageComp = false;
    this.getFileNameCompetitor = null;
    this.getFileSizeCompetitor = null;
    this.isMonthPickerVisible = false;
    this.isValidFromDateValidation =false;
    this.isValidFromDateVisible = false;
  }

  clearAllStepTwo() {
    this.clusteringData = this.multiStepForm.get('clustering').value;
    if (this.clusteringData) {
      this.utilService.getClusteringCheckValues([]);
    }

    this.spinnerService.show();
    let plCode = this.getPLCode();
    this.pairingStepFormApi
      .getClusteringValues(this.storeRegionId, this.stepLabelText, plCode)
      .subscribe(
        (res) => {
          this.spinnerService.hide();
          const clusteringLists = res;
          this.clusterings = clusteringLists.result;
          this.clusterings = this.clusterings.filter(function (el: any) {
            return el !== 'NULL';
          });
        },
        (err: any) => {
          this.spinnerService.hide();
          console.log(err);
        }
      );

    for (let key of this.clusterings) {
      if (!key.isDefaultSelected) {
        let i = 0;
        (<FormArray>this.multiStepForm.get('clustering')).controls.forEach(
          (val: any) => {
            if (val.value == key.parameterLabel) {
              (<FormArray>this.multiStepForm.get('clustering')).removeAt(i);
              return;
            }
            i++;
          }
        );
      }
    }
    this.clusteringData = this.multiStepForm.get('clustering').value;

    this.removeDegradationFile();
    this.multiStepForm.patchValue({
      ruleSheet: null,
    });
    this.isDegradationSelected = false;
    this.isButtonDisabledDeg = true;
    this.degradationError = null;
    this.errorFlagDeg = false;
    this.successMessageDeg = false;
    this.getFileName = null;
    this.getFileSize = null;
  }

  onClearDegradation() {
    this.isDownloadBoxDisabled = false;
    (<FormArray>this.multiStepForm.get('degradation')).clear();
    this.multiStepForm.patchValue({
      degradation: null,
      ruleSheet: null,
    });

    for (
      let i = 0;
      i < this.multiStepForm.get('inputFiles').value.length;
      i++
    ) {
      if (
        i == 0 &&
        this.multiStepForm.get('inputFiles').value.at(i).fileTypeId == 1
      ) {
        this.inputFilesFormControls.removeAt(0);
      }
      if (
        i == 1 &&
        this.multiStepForm.get('inputFiles').value.at(i).fileTypeId == 1
      ) {
        this.inputFilesFormControls.removeAt(1);
      }
      if (
        i == 2 &&
        this.multiStepForm.get('inputFiles').value.at(i).fileTypeId == 1
      ) {
        this.inputFilesFormControls.removeAt(2);
      }
    }

    this.degradationData = [];
    this.Loadintersection = [];
    this.isDegradationSelected = false;
    this.isButtonDisabledDeg = false;
    this.degradationError = null;
    this.errorFlagDeg = false;
    this.successMessageDeg = false;
    this.getFileName = null;
    this.getFileSize = null;
  }

  onSubmit() {
    this.spinnerService.show();
    if (this.step == 4) {
      this.pairingStepFormApi
        .onSubmit(this.multiStepForm.value, this.userId)
        .subscribe(
          (res: any) => {
            this.spinnerService.hide();
            const submitRes = res;
            this.pairingId = submitRes.result.pairingRequestId;
            const that = this;
            this.isSubmitedSuccessfully = true;
            this.isShowError = false;
            setTimeout(function () {
              that.isSubmitedSuccessfully = false;
              //that.router.navigate(['/pairingAsProduct']);
              that.router.navigateByUrl('/', {replaceUrl: true}).then(() =>
                that.router.navigate(['../pairingAsProduct']));
            }, 5000);
            this.utilService.goToTop();
          },
          (err: any) => {
            this.spinnerService.hide();
            this.isSubmitedSuccessfully = false;
            console.log(err);
            this.isShowError = true;
            const that = this;
            setTimeout(function () {
              that.isShowError = false;
              that.router.navigateByUrl('/', {replaceUrl: true}).then(() =>
                that.router.navigate(['../pairingAsProduct']));
            }, 5000);
            
            this.utilService.goToTop();
          }
        );
    }
  }

  onSaveDraft(isShowPopupMessage: boolean) {
    this.spinnerService.show();
    this.stepLabelText = this.getCurrentStepLabelText(this.maxStep);
    if (this.editMode) {
        this.handleEditMode1(this.maxStep);
    } else {
        this.handleNonEditMode(this.maxStep);
    }

    this.updateMultiStepForm();
    this.saveDraft(isShowPopupMessage);
}

private handleEditMode1(maxStep: number) {
    if (maxStep === 1 && (this.isStepOneInvalid() || this.isRuleSheetInvalid())) {
        this.stepLabelText = 'PERIMETER';
        this.clearForPerimeter();
    } else if (this.isRuleSheetInvalid() && this.step === 4) {
        this.stepLabelText = 'DEGRADATION';
        this.clearAllStepfour();
        this.updateCompetitorPrioritization();
    }
}

private handleNonEditMode(maxStep: number) {
  if (maxStep === 1 && this.isStepOneInvalid()) {
        this.stepLabelText = 'PERIMETER';
        this.clearForPerimeter();
    } else if (maxStep === 3 && (this.isStepThreeInvalid() || this.isRuleSheetInvalid())) {
      this.stepLabelText = 'DEGRADATION';
      this.clearAllStepfour();
  }
}

private isStepOneInvalid(): boolean {
    return this.step === 1 && 
           (!this.multiStepForm.get('regionCode').value || 
            !this.multiStepForm.get('productLine').value.length || 
            !this.multiStepForm.get('businessLine').value.length);
}

private isRuleSheetInvalid(): boolean {
    const ruleSheetValue = this.multiStepForm.get('ruleSheet').value;
    return ruleSheetValue === null || ruleSheetValue === undefined;
}

private isStepThreeInvalid(): boolean {
    return this.step === 3 && this.isRuleSheetInvalid();
}


private clearForPerimeter() {
    this.onClearDegradation();
    this.clearAllStepfour();
    this.updateCompetitorPrioritization();
}

private updateCompetitorPrioritization() {
    const ruleSheetValue = this.multiStepForm.get('ruleSheet').value;
    this.isCompetitorChecked = ruleSheetValue !== null && ruleSheetValue !== undefined;
    this.multiStepForm.patchValue({
        competitorPrioritization: this.isCompetitorChecked,
    });
}

private updateMultiStepForm() {
    this.multiStepForm.patchValue({
        id: this.id,
        stage: this.stepLabelText.toUpperCase(),
        status: 'DRAFT',
    });
    this.isDraftFile = true;
    this.isSaved = true;
}

private saveDraft(isShowPopupMessage: boolean) {
    this.pairingStepFormApi
        .onSaveDraft(this.multiStepForm.value, this.userId)
        .subscribe(
            (res: any) => this.handleSaveSuccess(res, isShowPopupMessage),
            (err: any) => this.handleSaveError(err)
        );
}

private handleSaveSuccess(res: any, isShowPopupMessage: boolean) {
    this.spinnerService.hide();
    const submitRes = res;
    this.id = submitRes.result.requestId;
    this.updatedDate = submitRes.result.updatedDate;
    this.isSavedSuccessfully = true;
    this.utilService.goToTop();
    this.isShowErrorMessageWithToast = true;
    this.isMultiStepFormDirty = false;

    if (this.isConfirmationPopupVisibale && this.currentActionType === this.SAVEDRAFT) {
        this.router.navigate([this.router.url]);
    }

    this.getTimeInterval();
    this.handlePopupMessage(isShowPopupMessage);
}

private handleSaveError(err: any) {
    this.isSavedSuccessfully = false;
    this.spinnerService.hide();
}

private handlePopupMessage(isShowPopupMessage: boolean) {
    if (isShowPopupMessage) {
        this.displayStyle = 'none';
        this.isConfirmationPopupVisibale = false;
        this.utilService.isTosterVisible(
            true,
            `Request ID ${this.id} has been updated successfully.`
        );
        setTimeout(() => {
            this.isShowErrorMessageWithToast = false;
            this.utilService.isTosterVisible(false, '');
            this.router.navigateByUrl('/', {replaceUrl: true}).then(() =>
              this.router.navigate(['../pairingAsProduct']));
        }, 5000);
       
    } else {
        this.isShowErrorMessageWithToast = false;
    }
}


  getTimeInterval() {
    let that = this;
    if (this.getDraftRecordById != undefined && this.updatedDate == undefined)
      this.updatedDate = this.getDraftRecordById?.updatedDate;
    let id = setInterval(function () {
      if (that.id) {
        let date = new Date(that.updatedDate);
        let current = new Date();
        let currentUtc = new Date(
          current.getUTCFullYear(),
          current.getUTCMonth(),
          current.getUTCDate(),
          current.getUTCHours(),
          current.getUTCMinutes(),
          current.getUTCSeconds()
        );
        let diff = Math.abs(currentUtc.getTime() - date.getTime());
        let timegap = Math.ceil(diff / 1000);

        if (timegap / 3600 < 1 && timegap / 60 > 1)
          that.timeInterval = Math.ceil(timegap / 60).toString() + ' minutes ';
        else if (timegap / 60 < 1)
          that.timeInterval = Math.ceil(timegap / 60).toString() + ' seconds ';
        else if (timegap / (3600 * 24) < 1 && timegap / 3600 > 1)
          that.timeInterval = Math.ceil(timegap / 3600).toString() + ' hours ';
        else if (timegap / (3600 * 24) > 1)
          that.timeInterval =
            Math.ceil(timegap / (3600 * 24)).toString() + ' days ';
      } else {
        clearInterval(id);
      }
    }, 5000);
  }

  checkElementNotNull(element: any) {
    if (element == null) {
      console.error(element);
    }
  }

  applyRegionWW() {
    this.isOrderPrefDisable = true;
    this.isPriceMonthDisable = true;
    this.isValidFromDateDisable = true;
    this.isPriceTypeDisable = true;
    this.isMultiStepFormDirty = true;
    (<FormArray>this.multiStepForm.get('orderPreference')).clear();
    this.multiStepForm.patchValue({
      orderPreference: null,
      mostPopular: 'False',
      mostRecent: 'False',
      priceDate: null,
      pricePeriodStart: null,
      pricePeriodEnd: null,
      priceType: null,
      valid_from_date: null,
      validFromDateValue: null,
    });
  }

  getParameterId(code: any) {
    this.productLines.forEach((productLine: any) => {
      if (code === productLine.code) {
        this.productLineId = productLine.productLineId;
      }
    });
  }

  getPLCode() {
    if (undefined != this.multiStepForm) {
      let PLArray = (<FormArray>this.multiStepForm.get('productLine')).value;
      if (PLArray.length > 0) {
        return PLArray[0];
      } else {
        return this.getDraftRecordById?.productLine.length > 0
          ? this.getDraftRecordById?.productLine[0]
          : 'TC';
      }
    }
  }

  checkCountryWW(regionCode: any) {
    if (regionCode != 'WW') {
      this.isCountryDisabledOnRegion = false;
    } else {
      this.isCountryDisabledOnRegion = true;
    }
  }

  clearClustering() {
    this.clusterings = [];
    this.clusteringData = [];
    (<FormArray>this.multiStepForm.get('clustering')).clear();
  }

  clearDegradation() {
    this.degradations = [];
    this.degradationData = [];
    (<FormArray>this.multiStepForm.get('degradation')).clear();
  }

  getMichelinTyreLines(brandIds: any, subSeasonIds: any, usageIds: any) {
    this.pairingStepFormApi
      .getMichelinTyreLines(brandIds, subSeasonIds, usageIds, this.getPLCode())
      .subscribe(
        (res) => {
          this.spinnerService.hide();
          const tyreLinesLists = res;
          this.tyreLines = tyreLinesLists.result;
          if (this.editMode && this.tyreLines != undefined) {
            this.selectedTyreLine = PairingHelper.getSelectedRecords(
              this.tyreLines,
              this.getDraftRecordById?.tirelineX ?? [],
              'categoryName'
            );
          }
          this.tyreLines = this.tyreLines.filter(function (el: any) {
            return el !== 'NULL';
          });
        },
        (err: any) => {
          this.spinnerService.hide();
        }
      );
  }

  getOtherTyreLines(brandIds: any, subSeasonIds: any, usageIds: any) {
    this.pairingStepFormApi
      .getOtherTyreLines(brandIds, subSeasonIds, usageIds, this.getPLCode())
      .subscribe(
        (res) => {
          this.spinnerService.hide();
          const otherTyreLineLists = res;
          this.otherTirelines = otherTyreLineLists.result;
          if (this.otherTirelines.length == 0) {
            this.isOtherTireLineDisabled = true;
          }
          this.otherTirelines = this.otherTirelines.filter(function (el: any) {
            return el !== 'NULL';
          });
          if (this.editMode && this.otherTirelines != undefined) {
            this.selectedOtherTireline = PairingHelper.getSelectedRecords(
              this.otherTirelines,
              this.getDraftRecordById?.tirelineY ?? [],
              'categoryName'
            );
          }
        },
        (err: any) => {
          this.spinnerService.hide();
          console.log(err);
        }
      );
  }

  getTyreUsage(brandIdList: any, allSeasonIdList: any, plCode: any) {
    // get tyre usage
    this.pairingStepFormApi
      .getTyreUsage(brandIdList, allSeasonIdList, plCode)
      .subscribe(
        (res) => {
          this.spinnerService.hide();
          const tyreUsageLists = res;
          this.tyreUsages = tyreUsageLists.result;
          this.tyreUsages = this.tyreUsages.filter(function (el: any) {
            return el !== 'NULL';
          });
          if (this.editMode && this.tyreUsages != undefined) {
            this.selectedTyreUsage = PairingHelper.getSelectedRecords(
              this.tyreUsages,
              this.getDraftRecordById?.usageX ?? [],
              'usageName'
            );
            if (this.selectedTyreUsage.length > 0)
              this.relativeActionOnTyreUsage();
          }
        },
        (err: any) => {
          this.spinnerService.hide();
        }
      );
    this.alllist = [];
  }

  getTyreOtherUsage(brandIdList: any, allSeasonIdList: any, plCode: any) {
    // get other tyre usage
    this.pairingStepFormApi
      .getOtherTyreUsage(brandIdList, allSeasonIdList, plCode)
      .subscribe(
        (res) => {
          this.spinnerService.hide();
          const OtherTyreUsageLists = res;
          this.otherTyreUsages = OtherTyreUsageLists.result;
          this.otherTyreUsages = this.otherTyreUsages.filter(function (
            el: any
          ) {
            return el !== 'NULL';
          });
          if (this.editMode && this.otherTyreUsages != undefined) {
            this.selectedOtherTyreUsage = PairingHelper.getSelectedRecords(
              this.otherTyreUsages,
              this.getDraftRecordById?.usageY ?? [],
              'usageName'
            );
            if (this.selectedOtherTyreUsage.length > 0)
              this.relativeActionOnOtherTyreUsage();
          }
        },
        (err: any) => {
          this.spinnerService.hide();
        }
      );
  }

  public displayTooltip(tooltipObject: MatTooltip){
    tooltipObject.disabled = false;
    tooltipObject.show()
    setTimeout(() => {
      tooltipObject.disabled = true;
    }, 10000);
  }
}
