import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss'],
})
export class MenusComponent implements OnInit {
  headerTabs: any;
  isHeaderTabVisible: boolean = false;
  typeSelected: string;
  userName: any;
  userId: string;
  platformApiUrl: any;
  isShowProfilecard: boolean = false;
  moduleByteMap: any;
  response: any;
  allHeadersTab: any = [];
  iconHeaders: any = [];
  sideHeaders: any = [];
  platformUrl: any;
  headerTabsObs: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  moduleBytes = new BehaviorSubject<any>('');

  DEV_PLATFORM_URL =
  'https://dev.platform.dacoval.com';
  QA_PLATFORM_URL =
  'https://qa.platform.dacoval.com';
  UAT_PLATFORM_URL =
  'https://uat.platform.dacoval.com';
  PROD_PLATFORM_URL = 'https://dmintplatform.azurewebsites.net';

  DEV_PLATFORM_API_URL =
  'https://dev-backend.platform.dacoval.com/dmint-platform/api';
  QA_PLATFORM_API_URL =
  'https://qa-backend.platform.dacoval.com/dmint-platform/api';
  UAT_PLATFORM_API_URL =
  'https://uat-backend.platform.dacoval.com/dmint-platform/api';
  PROD_PLATFORM_API_URL = 'https://dmintplatformapi.azurewebsites.net/dmint-platform/api';



  constructor(
    public auth: AuthService,    
    private readonly spinner: NgxSpinnerService,
    private readonly http: HttpClient
  ) {
    this.typeSelected = 'ball-clip-rotate-pulse';
    this.platformApiUrl =  environment.type.includes('qa')
                          ? this.QA_PLATFORM_API_URL
                          : environment.type.includes('uat')
                         ? this.UAT_PLATFORM_API_URL
                         : this.DEV_PLATFORM_API_URL;
    //this.platformApiUrl = this.PROD_PLATFORM_API_URL;

    this.platformUrl = environment.type.includes('qa')
                    ? this.QA_PLATFORM_URL
                    : environment.type.includes('uat')
                    ? this.UAT_PLATFORM_URL
                    : this.DEV_PLATFORM_URL;
    //this.platformUrl =  this.PROD_PLATFORM_URL;
  }

  ngOnInit(): void {
    this.spinner.show();
    const userId = sessionStorage.getItem('userId');
    if (userId != undefined) {
      this.headerTabsObs.subscribe((headers) => {
        if (headers == undefined || headers.length == 0) this.getHeaders();
        else this.allHeadersTab = headers;
      });

      this.moduleBytes.subscribe((moduleBytes) => {
        if (moduleBytes == undefined || moduleBytes.length == 0) this.downloadImages();
        else this.moduleByteMap = moduleBytes as Object;        
      });
    }

    this.auth.user$.subscribe((user) => {
      this.userName = user?.name;
    });
  }

  getHeaders() {
    this.spinner.show();
    const headers = new HttpHeaders({
      Accept: 'application/json',     
      userId: sessionStorage.getItem('userId')
    });
    return this.http
      .post<any>(`${environment.apiUrl}/v1/headers`, null, {      
        headers: headers,
      })
      .subscribe((res: any) => {
        const headerResult = res.result;
        this.allHeadersTab = headerResult;
        this.setHeaderTabs(this.allHeadersTab);

        this.allHeadersTab.forEach((header: any) => {
          
          if(header.homePageItem){     
            if(header.menuImagePath.includes('_icon.'))   
              this.iconHeaders.push(header); 
            else       
              this.headerTabs.push(header);                                        
          }
          else 
            this.sideHeaders.push(header);                         
        });
                
        this.spinner.hide();
      });
  }

  downloadImages(){
    this.http
    .get<Blob>(`${this.platformApiUrl}/v1/downloadAllCapabilityImages`)        
    .subscribe((res) => {
      this.response = res;
      this.moduleByteMap = this.response.result as Object;      
      this.setModuleByte(this.moduleByteMap);
    }); 
  }

  openLink(link: any) {
    window.open(`${link}`, '_blank');
  }

  logout() {
    this.spinner.hide();
    sessionStorage.clear();
    this.auth.logout();
  }

  onLoginCall() {
    this.auth.loginWithRedirect();
  }

  onShowUserProfile() {
    this.isShowProfilecard = !this.isShowProfilecard;
  }

  setHeaderTabs(headerTabs: any) {
    this.headerTabsObs.next(headerTabs);
  }
  setModuleByte(moduleBytesMap: any){
    this.moduleBytes.next(moduleBytesMap);
  }
}
